import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { DiligenciaOrganisationActor, DiligenciaPersonActor, DummyCompanyActor, DummyIndividualActor, IActorBase } from "src/app/dashboard/models/Actor";
import { DashboardService } from "src/app/services/dashboard.service";
import { Address2, BusinessClient, ClientResult, CompanyInfo, DiligenciaOrganisationInfo, DiligenciaPersonInfo, DueDiligenceClient, EntityTypes, FinCrimeCheckClient, GetAssociatedNodeForClientModel, IndividualClient, OfficerInfo, SharedModelsDiligenciaName } from 'src/nswag';
import { FeatureState } from "../enums";
import { ProfileService } from "../profile.service";
import { SearchDialogService } from "../search/search-dialog.service";
import { SelfServiceService } from "../self-service/services/self-service.service";
import { UtilityService } from "../utilitities/utilities";
import { AlertService } from "../_alert";

@Component({
  selector: "mon-investigate",
  templateUrl: "./investigation-linker.component.html",
  styleUrls: ["./investigation-linker.component.scss"]
})
export class InvestigationLinkerComponent implements OnChanges {

  @Input() profile: any;
  @Input() client: ClientResult;
  @Input() styling: boolean;

  private isIndividual: boolean;
  private isBusiness: boolean;
  private address: Address2;

  constructor(private dashService: DashboardService, private router: Router, private profileService: ProfileService, private selfService: SelfServiceService, private alertService: AlertService, private searchDialogueService: SearchDialogService, private finCrimeCheckClient: FinCrimeCheckClient, private ddClient: DueDiligenceClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.client) {
      return;
    }
    this.isIndividual = this.client?.client?.individual != null;
    this.isBusiness = this.client?.client?.business != null;
    this.address = this.client?.client.address
  }

  public viewInvestigation() {
    if (this.profileService.licenceType !== 'FreeTrial') {
      if (!this.profileService.searchEnabled()) {
        this.alertService.warn("Sorry, Search is not enabled for this account. Please contact support if you want to try it out!", { autoClose: true});
      }
      else {
        // check for a linked node
        this.finCrimeCheckClient.getAssociatedNodeForClient(this.client.client.id).subscribe(res => {
          let linkedNode: GetAssociatedNodeForClientModel = res.data;
          if (linkedNode?.nodeId) {
            if (linkedNode?.isPremiumNode) {
              // launch premium node
              let premiumActor: IActorBase;
              let actorType = this.isBusiness ? EntityTypes.DiligenciaOrganisation : EntityTypes.DiligenciaPerson;
              this.ddClient.getPremiumSummaryActor(Number(linkedNode.nodeId), actorType).subscribe(res => {
                if (res.data?.id) {
                  // tenant has previously expanded this actor, load full summary into node
                  premiumActor = this.isBusiness 
                    ? new DiligenciaOrganisationActor(res.data.diligenciaOrganisation) 
                    : new DiligenciaPersonActor(res.data.diligenciaPerson);
                } else {
                  // populate premium node with client details
                  premiumActor = this.createPremiumDummyActor(linkedNode.nodeId);
                }
                
                premiumActor.clientId = this.client.client.id;
                premiumActor.profileId = this.profile?.resourceId;
                this.launchSearchWithActor(premiumActor);
              }, (error: any) => {
                console.log(error);
              }); 
            }
            else {
              // launch as standard dummy node
              let dummy: IActorBase = this.createDummyActor();
              this.launchSearchWithActor(dummy);
            }
          } else {
            // launch as standard dummy node
            let dummy: IActorBase = this.createDummyActor();
            this.launchSearchWithActor(dummy);
          }
        });
    } 
  }
    else {
      this.selfService.openAdvertDialog(FeatureState.SEARCH);
    }
  }

  private launchSearchWithActor(actor: IActorBase) {
    this.dashService.addProfileToCache(this.client, this.profile);     
    this.dashService.setSelectedDummyActor(actor, this.client, this.profile);
    this.router.navigate(['search/dashboard']);
  }

  private createPremiumDummyActor(premiumId: string): IActorBase {
    let dummy: IActorBase;
    if (this.isBusiness) {
      const b: BusinessClient = this.client?.client?.business;
      dummy = new DiligenciaOrganisationActor(new DiligenciaOrganisationInfo({
        id: premiumId,
        diligenciaId: null,
        companyNameDto: new SharedModelsDiligenciaName({ englishName : b.name }),
        jurisdiction: UtilityService.getCodeFromCountry(b?.jurisdiction),
        registeredCountryDto:  new SharedModelsDiligenciaName({ englishName : b?.jurisdiction }),
        registeredAddress: UtilityService.convertToAddress(this.address)
      }));
    }
    else {
      const c: IndividualClient = this.client?.client?.individual;
      let address = UtilityService.convertToAddress(this.address);
      dummy = new DiligenciaPersonActor(new DiligenciaPersonInfo({
        id: premiumId,
        diligenciaId: null,
        nameDto: new SharedModelsDiligenciaName({ englishName : c.name }),
        dateOfBirth: c?.dateOfBirth, 
        address: address,
        genderDto:  new SharedModelsDiligenciaName({ englishName : c?.gender }),
        nationalityDto: new SharedModelsDiligenciaName({ englishName : c?.nationality }),
        countryOfResidenceDto: new SharedModelsDiligenciaName({ englishName : address?.country })
      }));
    }
    dummy.clientId = this.client.client.id;
    dummy.profileId = this.profile?.resourceId;
    dummy.hasLAMPS = (dummy.profileId != null);
    dummy.colour = dummy.hasLAMPS && dummy.profileId ? '#DC3545' : dummy.colour;
    return dummy;
  }

  private createDummyActor(): IActorBase {
    // Creates Dummy Actor based on the Client's Id!
    const id = this.client.client.id;
    let dummy: IActorBase;
    if (this.isBusiness) {
      const b: BusinessClient = this.client?.client?.business;
      dummy = new DummyCompanyActor(new CompanyInfo({
        id: id,
        isActive: true,
        name: b?.name,
        jurisdiction: UtilityService.getCodeFromCountry(b?.jurisdiction),
        jurisdictionName: b?.jurisdiction,
        registeredAddress: UtilityService.convertToAddress(this.address)
      }));
    }
    else {
      const c: IndividualClient = this.client?.client?.individual;
      dummy = new DummyIndividualActor(new OfficerInfo({
        isActive: true,
        id: id,
        name: c.name,
        dateOfBirth: c?.dateOfBirth,
        address: UtilityService.convertToAddress(this.address),
        gender: c?.gender,
        nationality: c?.nationality,
      }));
    }
    dummy.clientId = this.client.client.id;
    dummy.profileId = this.profile?.resourceId;
    dummy.hasLAMPS = true;
    dummy.colour = dummy.hasLAMPS && dummy.profileId ? '#DC3545' : dummy.colour;
    return dummy;
  }
}
