import { Component } from '@angular/core';
import { FeatureState } from 'src/app/enums';
import { SelfServiceService } from './services/self-service.service';


@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss']
})

export class SelfServiceComponent {
  
  constructor(public selfServeService: SelfServiceService) { }

  public displaySelfService(advertCriteria: string) {
    if (this.selfServeService.screeningCredits <= 0) {
      this.selfServeService.openAdvertDialog(FeatureState.OUTOFCREDITS)
    } //show out of credits pop up
    else {
      this.selfServeService.openAdvertDialog(advertCriteria);
    }
  }

}
