import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { ClientResult, Evidence, LAMPSStatus, SanEntries } from 'src/nswag';
import { ProfileService } from '../profile.service';
import { SelfServiceService } from '../self-service/services/self-service.service';
import { UtilityService } from '../utilitities/utilities';
import { CurrentModule } from '../monitor/client-report/client-report.component';
import { FeatureState } from '../enums';

@Component({
  selector: 'mon-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnChanges {
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;

  @Input() profile : any;
  @Input() client : ClientResult;
  @Input() allowDiscounting = true;
  @Input() screened = false;
  @Input() canClose = true;
  @Input() isArchived: boolean = false;
  @Input() currentModule: CurrentModule;

  @Output() clientChange = new EventEmitter<ClientResult>(); 
  @Output() addToMonitoringEvent = new EventEmitter<ClientResult>(); 
  @Output() searchMatchesEvent = new EventEmitter<boolean>();
  @Output() viewMatchesEvent = new EventEmitter<boolean>();

  public tab0Clicked: boolean;
  public tab1Clicked: boolean;
  public tab2Clicked: boolean;
  public tab3Clicked: boolean;
  public tab4Clicked: boolean;
  public tab5Clicked: boolean;

  public sanctions : SanEntries;
  public name : string;
  public isIndividual : boolean;
  public isBusiness : boolean;
  
  public evidences: Evidence[];
  public isMatched : boolean;
  public hasMatches : boolean;
  public matchesDiscounted: boolean;

  constructor(private alertService: AlertService, private profileService: ProfileService, private selfService: SelfServiceService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.sanctions = this.profile?.sanEntries;
  
    this.isIndividual = this.client?.client?.individual != null;
    this.isBusiness = this.client?.client?.business != null;

    if (this.profile) {
      this.name = this.isBusiness ? 
          this.profile?.name : 
          UtilityService.getIndividualName(this.profile);
    }
    else {
      let ind = this.client?.client?.individual;
      this.name = this.isBusiness ? 
          this.client?.client?.business?.name : UtilityService.getIndividualFullName(ind.name, ind.firstName, ind.otherName, ind.lastName);
    }  
    this.evidences = this.profile?.evidences;
    this.isMatched = this.client?.matchStatus?.isMatched == LAMPSStatus.Yes || this.client?.matchStatus?.isMatched == LAMPSStatus.Changed || this.client?.matchStatus?.isMatched == LAMPSStatus.Voided;
    this.hasMatches= this.client?.matchStatus?.matches != LAMPSStatus.No;
    this.matchesDiscounted = this.client?.matchStatus?.isMatched == LAMPSStatus.Discounted;    
    this.screened = this.client?.client.screened;
  }

  onClientChange(newClient: ClientResult) {
    this.clientChange.emit(newClient);
    this.client = newClient;
  }

  public setTabAsClicked(tabNumber: number) {
    switch(tabNumber) { 
      case 0: { 
        this.tab0Clicked = true;
        break; 
      } 
      case 1: { 
        this.tab1Clicked = true;
        break; 
      } 
      case 2: { 
        this.tab2Clicked = true;
        break; 
      } 
      case 3: { 
        this.tab3Clicked = true;
        break; 
      } 
      case 4: {
        this.tab4Clicked = true;
        break;
      } 
      case 5: {
        this.tab5Clicked = true;
        break;
      } 
      default: { 
        break; 
      }
    }
  }

  public close() {
    this.clientChange.emit(null);
  }

  public viewMatches() {
    this.viewMatchesEvent.emit(true);
  }

  public addToMonitoring() {
    if (!this.profileService.monitoringEnabled()) {
      if (this.profileService.licenceType !== 'Commercial') {
        this.selfService.openAdvertDialog(FeatureState.MONITOR);
      }
      else this.alertService.warn("Sorry, Monitoring is not enabled for this account. Please contact support if you want to try it out!");
    }
    else this.addToMonitoringEvent.emit(this.client);
  }

  public searchMatches() {
    this.searchMatchesEvent.emit(true);
  }
}
