<div id="monitor-adversemedia">
    <p class="alert alert-info my-3" *ngIf="rreEntries?.length > 0 && evidences?.length > 0">
        Selecting <strong>View URL Evidence</strong> will direct you to the link. Please note that the source may have expired and is provided by a third-party. Select <strong>View PDF Evidence</strong> to access the PDF version
    </p>
    <h5 *ngIf="incHeader">Adverse Media</h5>
    <div *ngIf="rreEntries?.length >0; else noentries">
        <p class="alert alert-danger">
            Adverse media has been reported against {{name}}
        </p>
        <div *ngFor="let entry of rreEntries; let pos=index">
            <div *ngIf="checkCategory(entry)">
                <div id="left-card">
                    <span class="displaydetail-style">{{getCategory(entry)}}</span>
                </div>
                <div>
                    <mon-events [clientId]="clientId" [profile]="profile" [events]="entry.events" [evidences]="evidences" [allowDiscounting] = "allowDiscounting" [incHeader]="incHeader" [reportMode]="reportMode"></mon-events>
                </div>
                <hr *ngIf="pos < rreEntries.length-1"/>
            </div>
        </div>
    </div>
    <ng-template #noentries>
        <div class="col alert alert-info">No adverse media information was found.</div>
    </ng-template>
    <div *ngIf="showEntry() && rreEntries?.length > 0">
        <div class="col alert alert-info"><p>Please refer to the law enforcement (and/or sanctions) tab for more information.</p></div>
    </div>
</div>
