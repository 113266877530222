<div class="modal-header">
  <h4 class="modal-title col">Select items to add to the risk map by clicking on the checkboxes.</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!selectedActorsWithinGraphLimits()" class="form-control alert alert-danger">
    <small>You have exceeded the maximum number of nodes that can be added to the risk map. Please consider removing some from your investigation or reduce the number selected before proceeding.</small>
  </div>
  <div *ngIf="sourceActor?.asDiligenciaOrgInfo != null || sourceActor?.asDiligenciaPersonInfo != null then premiumExpansionResults else standardExpansionResults"></div>
</div>
<div class="modal-footer">
  <button class="btn text-light btn-primary" type="button" [disabled]="!canAddToGraph()" (click)="addToGraph()">Add to Risk Map</button>
</div>

<ng-template #premiumExpansionResults>
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col">
          <input class="form-check-input" type="checkbox" (change)="selectAll($event)">
        </th>
        <th scope="col">Individual/Company</th>
        <th scope="col">Jurisdiction/Nationality</th>
        <th scope="col">Relationship</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let expandActor of expandActorList" (click)="selectActor(expandActor)">
        <td><input class="form-check-input" type="checkbox" id="labels" name="labels" [ngModel]="checkSelectedStatus(expandActor)"></td>
        <td id="filter">{{getPremiumActorsName(expandActor)}}</td>
        <td id="filter">{{getPremiumActorsLocation(expandActor)}}</td>
        <td id="filter">{{getPremiumActorsRelationshipTypes(expandActor)}}</td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #standardExpansionResults>
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col" sortable="">
          <input class="form-check-input" type="checkbox" (change)="selectAll($event)">
        </th>
        <th scope="col" sortable="PersonCompanyOperationName">Entities</th>
        <th scope="col" sortable="PersonActive">Status</th>
        <th scope="col" sortable="PersonPosition">Position</th>
        <th scope="col" sortable="PersonJurisdiction">Jurisdiction</th>
        <th scope="col" sortable="PersonAddress">Address</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let expandActor of expandActorList" (click)="selectActor(expandActor)">
        <td><input class="form-check-input" type="checkbox" id="labels" name="labels" [ngModel]="checkSelectedStatus(expandActor)"></td>
        <td id="filter">{{expandActor.actor.name}}<br />{{getCompanyInfo(expandActor)}}</td>
        <td id="filter">{{isActive(expandActor)}} {{expandActor.actor.isUBO ? " (UBO)" : ""}}</td>
        <td id="filter">{{getPosition(expandActor)}}</td>
        <td id="filter">{{getJurisdiction(expandActor)}}</td>
        <td id="filter">{{getFullAddress(expandActor)}}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
