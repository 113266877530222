import { Component, HostListener, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import cola from 'cytoscape-cola';
import bilkent from 'cytoscape-cose-bilkent';
import dagre from 'cytoscape-dagre';
import cytoscapePopper  from 'cytoscape-popper';
import { createPopper } from '@popperjs/core';
import { DueDiligenceClient, EntityTypes, FinCrimeCheckClient, ISubjectInfo, StatusOutcome, UserGraph } from 'src/nswag';
import { SearchCriteria } from '../../search/models/search-criteria';
import { SearchDialogService } from '../../search/search-dialog.service';
import { DashboardService, GraphData } from '../../services/dashboard.service';
import { GraphService } from '../../services/graph.service';
import { AlertService } from '../../_alert';
import { ActorBase, ActorEdge, CompanyActor, DiligenciaOrganisationActor, DiligenciaPersonActor, DummyCompanyActor, edgeType, GroupActor, IActorBase, IDVActor, IndividualActor, OfficerActor, OfficerCompanyActor } from '../models/Actor';
import { TippyController } from './graphController';
import { NodeExpansionService } from './node-expansion/node-expansion.service';
import { PremiumSearchUtilityService } from 'src/app/utilitities/premium-search-utilities';
import cytoscape from 'cytoscape';
import { IdvStatusCodeService } from 'src/app/idv/status-codes.service';

cytoscape.use(dagre);
cytoscape.use(bilkent);
cytoscape.use(cola);
cytoscape.use(cytoscapePopper(createPopper));

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})

@HostListener('window:resize', ['$event'])

export class GraphComponent implements OnInit, OnDestroy {
  private saveSubscription: any;
  public isBusy: boolean = false;
  public nodeSearchText: string;
  public disableChartStyle = false;
  private cy: cytoscape.Core;

  // Used in thbinding to html controls
  public layoutType: string = "cola";
  public tippyController: TippyController;

  constructor(public dashService: DashboardService, private graphService: GraphService, private alertService: AlertService, private ddClient: DueDiligenceClient, public router: Router, public sanitizer: DomSanitizer, private searchDialogService: SearchDialogService, private finCrimeChecksClient: FinCrimeCheckClient, private nodeExpansionService: NodeExpansionService, public premiumUtils: PremiumSearchUtilityService) {
    // We are listening for a save event
    this.saveSubscription = this.dashService.saveInvestigationObs$.subscribe(e => {
      this.saveSearch();
    });

    this.tippyController = new TippyController(this.dashService, this.premiumUtils);
  }

  private alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  public splitEdgeDescriptionByPipe(edgeDesc: string): string[] {
    return edgeDesc.split('|');
  }

  ngOnInit() {
    // Retrieve the graph elements if loading from a saved graph
    let selectedGraphData = this.dashService.getSelectedGraphData();

    this.dashService.graphLayout.name = this.layoutType;

    if (!selectedGraphData) {
      // Make sure the name of the investigation is reset.
      this.dashService.investigationName = "";
    }

    // Holding in the service so we can get it from other components (reporting)
    this.dashService.cytoscapeObject = this.cy = cytoscape({
      boxSelectionEnabled: false,
      container: document.getElementById("cy"),
      elements: [],
      layout: this.dashService.graphLayout,
      style: [
        {
          selector: 'node[name]',
          style: {
            'content': 'data(name)',
            'background-color': 'white',
            'border-color': '#20706B',
            'border-width': '1px',
            'font-size': '.8em',
            'text-transform': 'none'
          }
        },
        {
          selector: 'edge',
          style: {
            'curve-style': 'bezier',
            'target-arrow-shape': 'none',
            'width': '1pt',
            'font-size': '.6em',
            'line-color': '#68BF98'
          }
        }
      ]
    });

    // Hold local this pointer to handle js callbacks
    var self = this;
    this.cy.on('mouseover', 'node', function (e: cytoscape.EventObject) {
      self.tippyController.handleNodeMouseOver(e);
    });
    this.cy.on('tap', 'node', function (e: cytoscape.EventObject) {
      self.tippyController.handleNodeClick(e);
    });
    this.cy.on('mouseout', 'node', function (e: cytoscape.EventObject) {
      self.tippyController.handleMouseOut(e);
    });
    this.cy.on('mouseover', 'edge', function (e: cytoscape.EventObject) {
      if (!self.dashService.showLabels) {
        self.tippyController.handleEdgeMouseOver(e);
      }
    });
    this.cy.on('mouseout', 'edge', function (e: cytoscape.EventObject) {
      self.tippyController.handleMouseOut(e);
    });
    this.cy.on('tap', 'edge', function (e: cytoscape.EventObject) {
      self.tippyController.handleEdgeClick(e);
    });
    this.cy.on('tap', function (e: cytoscape.EventObject) {
      if (e.target === self.cy) {
        self.tippyController.handleCanvasClick(e);
      }
    });
    this.cy.on('free', 'node', function (e: cytoscape.EventObject) {
      self.tippyController.handleNodeTapEnd(e);
    });

    this.cy.on('layoutstart', function (e) {
      self.disableChartStyle = true;
    });
    this.cy.on('layoutstop', function (e) {
      self.disableChartStyle = false;
    });
    this.dashService.excludeInactive = false;
    this.dashService.showLabels = false;
    this.dashService.removedCollection = Array<cytoscape.CollectionReturnValue>();
    this.dashService.inactiveCollection = Array<cytoscape.CollectionReturnValue>();
    this.dashService.hiddenNodesList = Array<string>();

    let lampsCheckNodeId: string;

    if (selectedGraphData) {
      // Load from saved graph
      this.dashService.excludeInactive = selectedGraphData.excludeInactive;
      this.dashService.showLabels = selectedGraphData?.showLabels ?? false;
      this.dashService.hiddenNodesList = selectedGraphData?.hiddenNodeList ?? Array<string>();
      this.cy.json({ elements: selectedGraphData?.cytoElements }).layout({ name: 'preset' }).run();
      this.reinstateGraph();
      this.layoutType = this.dashService.graphLayout.name = selectedGraphData.layout ?? "cola";
      this.dashService.refreshStyleSheets();
    }
    else if (this.dashService.getSelectedDummyActor()) {
      // create a dummy actor
      var actor = this.dashService.getSelectedDummyActor();
      if (!actor?.hasLAMPS) lampsCheckNodeId = actor.id;
      this.dashService.addActorNode(actor)
      this.setInvestigation(actor);
      setTimeout(() => {
        this.resetMap();
      }, 500);
    }
    else if (this.dashService.getIDVPerson()) {
      // create from idv
      let idvActor = new IDVActor(this.dashService.getIDVPerson());
      if (!actor?.hasLAMPS) lampsCheckNodeId = idvActor.id;
      this.dashService.addActorNode(idvActor);
      this.setInvestigation(idvActor);
    }
    else {
      let subject: ISubjectInfo = this.dashService.getSelectedSubject();
      if (!subject) {
        this.router.navigate(['/']);
      }
      if (subject?.officer) {
        let officer = new OfficerActor(subject.officer);
        if (!actor?.hasLAMPS) lampsCheckNodeId = officer.id;
        this.dashService.addActorNode(officer);
        this.setInvestigation(officer);
        let oc = new OfficerCompanyActor(subject.officer);
      }
      if (subject?.individual) {
        let individual = new IndividualActor(subject.individual);
        if (!actor?.hasLAMPS) lampsCheckNodeId = individual.id;
        this.dashService.addActorNode(individual);
        this.setInvestigation(individual);
      }
      else if (subject?.company) {
        let company = new CompanyActor(subject.company);
        if (!actor?.hasLAMPS) lampsCheckNodeId = company.id;
        this.dashService.addActorNode(company);
        this.setInvestigation(company);
      }
      else if (subject?.diligenciaPerson) {
        let person = new DiligenciaPersonActor(subject.diligenciaPerson);
        if (!actor?.hasLAMPS) lampsCheckNodeId = person.id;
        this.dashService.addActorNode(person);
        this.setInvestigation(person);
      }
      else if (subject?.diligenciaOrganisation) {
        let org = new DiligenciaOrganisationActor(subject.diligenciaOrganisation);
        if (!actor?.hasLAMPS) lampsCheckNodeId = org.id;
        this.dashService.addActorNode(org);
        this.setInvestigation(org);
      }
    }

    if (lampsCheckNodeId) {
      this.dashService.singleLAMPSGet(lampsCheckNodeId);
    }

    setTimeout(() => {
      // after the first load
      self.dashService.hasChanged = false;
    }, 1000);
  }

  public reinstateGraph() {
    var self = this;
    this.cy.nodes().forEach(function (node: cytoscape.NodeSingular) {
      var actor: IActorBase = node.data('actor');
      if (actor) {
        // Need to reinstate the actual actor type each reload
        actor = ActorBase.ReinstateActor(actor);
        node.data('actor', actor);
        // Check if this is the actor that was currently under investigation
        if (actor.id == self.dashService.getSelectedUserGraph().nodeId) {
          self.setInvestigation(actor);
        }
        if (actor.hasLAMPS) {
          actor.hasLAMPS = true;
        }

        self.dashService.bulkLAMPSToCheck.push(actor.id);

        self.dashService.setNodeStyle(node);
        if (actor.useFocusStyle) {
          node.style('border-width', '2px');
          node.style('font-size', '1em',);
        }
      }
      else {
        // This must be an old group - so let's check
        actor = new GroupActor("", node.data().id);
        node.data('actor', actor);
        node.data('groupname', "");
      }
    });

    if (this.dashService.bulkLAMPSToCheck.length > 0) {
      this.dashService.bulkLAMPSGet();
    }

    this.cy.edges().forEach(function (edge: any) {
      var actorEdge: ActorEdge = edge.data('actorEdge');
      actorEdge.sourceActor = self.dashService.findNode(actorEdge.sourceActor.id)?.data("actor");
      actorEdge.targetActor = self.dashService.findNode(actorEdge.targetActor.id)?.data("actor");
      edge.data('description', actorEdge.description);
      self.dashService.setEdgeStyle(edge);
    });
    // Now to reset any removed or inactive node from the graph
    var graphData = self.dashService.getSelectedGraphData();
    if (graphData) {
      if (this.dashService.excludeInactive && graphData.inactiveNodeList) {
        this.resetNodeState(graphData.inactiveNodeList, this.dashService.inactiveCollection);
      }
      this.resetNodeState(graphData.removedNodeList, this.dashService.removedCollection);
    }
  }

  resetMap(): void {
    this.cy.fit();
  }

  canSearchTippyNode(): boolean {
    return this.tippyController?.sourceActor?.actorType != EntityTypes.Company &&
      this.tippyController?.sourceActor?.actorType != EntityTypes.Operation;
  }

  searchTippyNode(): void {
    if (this.canSearchTippyNode()) {
      this.isBusy = true;
      this.nodeExpansionService.expandNode(this.tippyController?.sourceActor).subscribe(r => {
        this.isBusy = false;
      });
    }
    this.hideTippyController(true);
  }

  canExpandNode(actor: IActorBase = null): boolean {
    actor = actor ?? this.tippyController.sourceActor;
    if (!actor) {
      return false;
    }
    if (this.dashService.collapsedMap.get(actor.id) != null) {
      // User collapsed the node
      return true;
    }
    return !actor.isExpanded;
  }

  expandNode(actor: IActorBase = null): void {
    actor = actor ?? this.tippyController.sourceActor;
    if (this.canExpandNode(actor)) {
      var col = this.dashService.collapsedMap.get(actor.id);
      if (col) {
        col.forEach(c => {
          this.dashService.tryRestore(c);
        });
        this.dashService.collapsedMap.delete(actor.id);
        this.dashService.addAuditTrail(`Expanded collapsed node ${actor.name}`);
      }
      else {
        this.isBusy = true;
        this.nodeExpansionService.expandNode(actor).subscribe(r => {
          this.isBusy = false;
        });
      }
      this.hideTippyController(true);
    }
  }

  canRemoveTippyNode(): boolean {
    return this.tippyController?.canRemoveNode;
  }
  canRemoveTippyEdge(): boolean {
    return true;
  }

  canCollapseTippyNode(): boolean {
    return this.tippyController?.canCollapseNode;
  }

  actorLinks(actor: IActorBase): number {
    var nodeCount: number = 0;
    this.cy.edges().forEach((edge: cytoscape.EdgeSingular, i: number) => {
      if (edge.data("source") == actor.id || edge.data("target") == actor.id) {
        nodeCount++;
      }
    });
    return nodeCount;
  }

  attachColorPaleteTo(): string {
    return (this.dashService.isFullscreen ? 'graph-fullscreen' : document.body.id);
  }

  excludeInactiveNodes() {
    var self = this;
    do {
      var retry = false;
      this.cy.nodes().forEach(function (node: any) {
        var actor: IActorBase = node.data('actor');
        // don't hide the investigated actore or any ubos!
        // TODO - note that an officer ubo may not be active because 1. they are no longer a director or 2. coz the company is nto active. The latter case needs fixing at some stage
        if (actor && actor.id != self.dashService.getInvestigation().id && !actor.isActive && !actor.isUBO && self.actorLinks(actor) < 2) {
          retry = true;
          var removedNode = node.remove();
          self.dashService.inactiveCollection.push(removedNode);
        }
      });
    } while (retry);
  }

  changeInactiveState() {
    this.dashService.excludeInactive = !this.dashService.excludeInactive;
    if (this.dashService.excludeInactive) {
      this.dashService.addAuditTrail("Inactive nodes disabled");
      this.excludeInactiveNodes();
    }
    else {
      // We are enabling inactive nodes
      this.enableNodes(this.dashService.inactiveCollection);
      this.dashService.addAuditTrail("Inactive nodes enabled");
    }
  }

  changeLabelState() {
    this.dashService.showLabels = !this.dashService.showLabels;
    this.dashService.refreshStyleSheets();
  }

  undoLastRemove() {
    var collection = this.dashService.removedCollection;
    if (collection) {
      var value = collection.pop();
      if (value) {
        if (this.dashService.tryRestore(value)) {
          let hasNodes = false;
          value.forEach((e) => {
            if (e.isNode()) {
              hasNodes = true;
              this.dashService.addAuditTrail(`Node ${e.data().name} was restored`);
            }
          });
          if (!hasNodes) {
            value.forEach((e) => {
              if (e.isEdge()) {
                let actorEdge: ActorEdge = e.data().actorEdge;
                if (actorEdge) {
                  this.dashService.addAuditTrail(`Link between ${actorEdge?.sourceActor?.name} and ${actorEdge?.targetActor?.name} was restored`);
                }
              }
            });
          }
        }
        else {
          this.alertService.info("It was not possible to complete the last undo", this.alertServiceOptions);
        }
      }
    }
  }

  enableNodes(collection: cytoscape.CollectionReturnValue[]): string[] {
    var results: string[] = new Array<string>();
    while (collection.length > 0) {
      var value = collection.pop();
      try {
        value.forEach((e) => {
          if (e.isNode()) {
            if (!this.dashService.findNode(e.id())) {
              this.dashService.addActorNode(e.data("actor"), undefined, true); //, _e._private.position);
              var node = this.dashService.findNode(e.id());
              var _e: any = e;
              node.position(_e._private.position);
              results.push(e.id());
            }
          }
        });
        value.forEach((e) => {
          if (e.isEdge()) {
            if (!this.dashService.findEdge(e.id())) {
              var actorEdge: ActorEdge = e.data("actorEdge");
              if (!actorEdge?.sourceActor || !this.dashService.findNode(actorEdge.sourceActor.id)) {
                // The source actor has been removed - Do nothing
              }
              else if (!actorEdge?.targetActor || !this.dashService.findNode(actorEdge.targetActor.id)) {
                // The target actor has been removed - Do nothing
              }
              else {
                this.dashService.addActorEdge(actorEdge.sourceActor, actorEdge.targetActor, actorEdge.type, actorEdge.description, actorEdge.reason);
                results.push(e.id());
              }
            }
          }
        })
      }
      catch (Error) {
        console.error(Error.message);
      }
    }
    return results;
  }

  removeTippyNode(): void {
    if (this.tippyController?.canRemoveNode) {
      this.tippyController.sourceActor.discountReason = this.sanitizer.sanitize(SecurityContext.HTML, this.tippyController.reason);

      var removedNode = this.tippyController.sourceNode.remove();
      this.dashService.removedCollection.push(removedNode);
      this.dashService.addAuditTrail(`Removed node ${this.tippyController.sourceActor.name}. ${this.tippyController.sourceActor.discountReason}`);

      let parentId = this.tippyController.sourceNode.data().parent;
      if (parentId) {
        let parentNode = this.dashService.findNode(parentId);
        if (parentNode) {
          if (parentNode.children().length == 0) {
            parentNode.remove();
            let groupName = parentNode.data().groupname ? parentNode.data().groupname : "Untitled";
            this.dashService.addAuditTrail('Group ' + groupName + " removed from risk map.");
          }
        }
      }
    }
    this.hideTippyController(true);
  }
  removeTippyEdge(): void {
    if (this.tippyController?.canRemoveNode && this.tippyController?.actorEdge) {
      this.tippyController.actorEdge.discountReason = this.sanitizer.sanitize(SecurityContext.HTML, this.tippyController.reason);
      let removedEdge = this.tippyController.cytoEdge.remove();
      this.dashService.removedCollection.push(removedEdge);
      this.dashService.addAuditTrail(`Removed edge between ${this.tippyController.actorEdge.sourceActor.name} and  ${this.tippyController.actorEdge.targetActor.name} `);
    }
    this.hideTippyController(true);
  }

  searchNodes(filterText: any) {
    var elements = this.dashService.cytoscapeObject.elements('node[name@^="' + filterText + '"]');
    if (elements.length == 0) {
      elements = this.dashService.cytoscapeObject.elements('node[name@$="' + filterText + '"]');
    }
    if (elements?.length > 0) {
      this.dashService.cytoscapeObject.fit(elements);
    } else {
      this.dashService.cytoscapeObject.reset();
    }
  }

  public saveEdgeLabel() {
    if (this.tippyController.actorEdge.description != this.tippyController.edgeLabel) {
      this.dashService.addAuditTrail(`Label changed from ${this.tippyController.actorEdge.description} to ${this.tippyController.edgeLabel}`);
      this.tippyController.actorEdge.description = this.tippyController.edgeLabel;
      this.tippyController.cytoEdge.data().description = this.tippyController.edgeLabel;
    }
    this.hideTippyController(true);
  }

  public saveGroupLabel() {
    if (this.tippyController.sourceActor.name != this.tippyController.groupName) {
      this.dashService.addAuditTrail(`Group label changed from ${this.tippyController.sourceActor.name} to ${this.tippyController.groupName}`);
      this.tippyController.sourceActor.name = this.tippyController.groupName;
      this.tippyController.sourceNode.data().groupname = this.tippyController.groupName;
    }
    this.hideTippyController(true);
  }

  public removeGroup() {
    this.tippyController.sourceNode.children().forEach(n => {
      n.move({ parent: null });
    });
    this.tippyController.sourceNode.remove();
    let groupName = this.tippyController.sourceNode.data().groupname;
    groupName = groupName ? groupName : "Untitled";
    this.dashService.addAuditTrail('Group ' + groupName + " removed from risk map.");
    this.hideTippyController(true);
  }

  public collapseTippyNode(): void {
    if (this.tippyController?.canCollapseNode && this.tippyController?.collapsedActorList.length > 0) {
      var collapseCol: cytoscape.CollectionReturnValue[] = Array<cytoscape.CollectionReturnValue>();

      this.tippyController.collapsedActorList.forEach(id => {
        var node = this.dashService.findNode(id);
        if (node) {
          var actorNode = node.data("actor");
          if (!actorNode?.useFocusStyle) {
            var collapsedNode = node.remove();
            collapseCol.push(collapsedNode);
          }
        }
      });
      this.dashService.collapsedMap.set(this.tippyController.sourceActor.id, collapseCol);
      this.dashService.addAuditTrail(`Collapsed node ${this.tippyController.sourceActor.name}`);
      this.tippyController.sourceActor.isExpanded = false;
      this.hideTippyController(true);
    }
  }

  hideTippyController(force: boolean = false) {
    this.tippyController.hideTippy(force);
  }

  changeLayout(): void {
    this.dashService.graphLayout.name = this.layoutType;
    this.refreshLayout();
  }

  getIDVDateOfBirth() {
    if (this.tippyController.sourceActor?.asUserProfile?.dateOfBirth) {
      return new Date(this.tippyController.sourceActor?.asUserProfile?.dateOfBirth).toLocaleDateString();
    }
    return "";
  }

  statusCodeToClass(status: StatusOutcome): string {
    return IdvStatusCodeService.statusCodeToClass(status);
  }

  onResize(event) {
    this.cy.resize();
    this.cy.fit();
  }

  ngOnDestroy(): void {
    this.hideTippyController(true);

    // Cancel the listener
    this.saveSubscription.unsubscribe();
  }

  setInvestigation(actor: IActorBase) {
    let lastActor = this.dashService.getActorByNodeId(this.dashService.getInvestigation()?.id);
    if (lastActor) {
      var node = this.dashService.findNode(lastActor.id);
      if (node) {
        node.style('border-width', '1px');
        node.style('font-size', '.8em',);
        lastActor.useFocusStyle = false;
      }
    }
    var node = this.dashService.findNode(actor.id);
    if (node) {
      node.style('border-width', '2px');
      node.style('font-size', '1em',);
    }
    actor.useFocusStyle = true;
    this.dashService.setInvestigation(actor);
  }

  setInvestigationSubject(): void {
    if (this.tippyController.sourceActor) {
      this.setInvestigation(this.tippyController.sourceActor);
      this.dashService.refreshIcons.next(true);
      this.hideTippyController(true);
    }
  }

  refreshLayout(): void {
    this.dashService.refreshLayout();
  }

  saveSearch(): void {
    try {
      this.isBusy = true;

      var graphData = new GraphData();

      graphData.excludeInactive = this.dashService.excludeInactive;
      graphData.showLabels = this.dashService.showLabels;
      graphData.hiddenNodeList = this.dashService.hiddenNodesList;
      graphData.inactiveNodeList = this.enableNodes(this.dashService.inactiveCollection);
      graphData.removedNodeList = this.enableNodes(this.dashService.removedCollection);
      graphData.cytoElements = this.cy.json();
      graphData.layout = this.layoutType;

      graphData.cytoElements.elements.nodes.forEach(element => {
        if (element.data.actor?.reportActorFreeText) {
          element.data.actor.reportActorFreeText = JSON.stringify(element.data.actor.reportActorFreeText.value);
        }
      });

      if (this.dashService?.subjectEditHistory?.length > 0) {
        this.dashService.subjectEditHistory.forEach(edit => {
          this.dashService.addAuditTrail(`Set ${edit.fieldName} as '${edit.fieldValue}' for node ${edit.subjectName}`);
        });
        this.dashService.subjectEditHistory = [];
      }

      let selectedUserGraph = this.dashService.getSelectedUserGraph();
      let newgraph = (!selectedUserGraph);
      if (newgraph) {
        selectedUserGraph = new UserGraph();
      }

      selectedUserGraph.graph = JSON.stringify(graphData);
      selectedUserGraph.name = this.dashService.investigationName;
      selectedUserGraph.nodeId = this.dashService.getInvestigation().id;
      // storing FormGroup.value because of an infinite object structure with the parent
      selectedUserGraph.formData = JSON.stringify(this.dashService.reportForms.value);

      selectedUserGraph.auditTrail = this.dashService.getEntriesAsXml();

      try {
        if (newgraph) {
          this.graphService.addGraph(selectedUserGraph).subscribe(r => {
            this.alertService.success('The graph has been saved successfully', this.alertServiceOptions);
            selectedUserGraph.id = r.data.id;
            selectedUserGraph.owner = r.data.owner;
            selectedUserGraph.userId = r.data.userId;
            this.dashService.setSelectedUserGraph(selectedUserGraph, false);
            this.dashService.hasChanged = false;
            this.isBusy = false;
          },
            error => {
              console.error(error);
              this.isBusy = false;
            });
        }
        else {
          this.graphService.updateGraph(selectedUserGraph.id, selectedUserGraph).subscribe(r => {
            this.alertService.success('The graph has been saved successfully', this.alertServiceOptions);
            this.dashService.hasChanged = false;
            this.isBusy = false;
          },
            error => {
              console.error(error);
              this.isBusy = false;
            });
        }
      }
      finally {
        // Need to re-load any deleted nodes before saving
        this.resetNodeState(graphData.inactiveNodeList, this.dashService.inactiveCollection);
        this.resetNodeState(graphData.removedNodeList, this.dashService.removedCollection);
      }
    }
    catch (Error) {
      console.error(Error.message);
      this.isBusy = false;
    }
  }

  resetNodeState(list: string[], collection: cytoscape.CollectionReturnValue[]) {
    var self = this;
    list.forEach((v) => {
      let node = this.dashService.findNode(v);
      if (node) {
        var removedNode = node.remove();
        collection.push(removedNode);
      }
      else {
        let edge = this.dashService.findEdge(v);
        if (edge) {
          let removedEdge = edge.remove();
          collection.push(removedEdge);
        }
      }
    });
  }

  createNewNode() {
    this.hideTippyController(true);
    var criteria: SearchCriteria = new SearchCriteria();
    this.searchDialogService.showSearch(criteria)
      .then((ok) => {
        if (ok) {
          var node = this.dashService.findNode(this.searchDialogService.selectedActor.id);
          if (node) {
            this.alertService.info("This subject " + this.searchDialogService.selectedActor.name + " already exists on the graph.", this.alertServiceOptions);
          }
          else {
            // check node for linked profile and add id to actor
            var self = this;
            this.finCrimeChecksClient.getIdentifiersForSingleNode(this.searchDialogService.selectedActor.id).subscribe(result => {
              if (result.isSuccess) {
                if (result?.data) {
                  self.searchDialogService.selectedActor.hasLAMPS = result?.data?.profileId ? true : false;
                  self.searchDialogService.selectedActor.profileId = result?.data?.profileId;
                  self.searchDialogService.selectedActor.clientId = result?.data?.clientId;
                }
                self.dashService.addActorNode(self.searchDialogService.selectedActor, self.tippyController.position);
              }
            });
          }
        }
      })
      .catch((onRejected) => { /* modal closed */ });
  }

  // Edge creation
  isLinkingStarted: boolean = false;
  startActor: IActorBase;
  linkDescription: string;

  startAddLink() {
    this.startActor = this.tippyController.sourceActor;
    this.hideTippyController(true);
    this.isLinkingStarted = true;
  }

  endAddLink(create: boolean) {
    if (create) {
      if (!this.linkDescription) {
        return;
      }
      if (this.premiumUtils.isPremiumNode(this.startActor) && this.premiumUtils.isPremiumNode(this.tippyController.sourceActor)) {
        this.alertService.error('You cannot create links between premium subjects', this.alertServiceOptions);
      }
      else if (this.startActor.id == this.tippyController.sourceActor.id) {
        this.alertService.error('You cannot create links between the same subject', this.alertServiceOptions);
      }
      else if (!this.dashService.addActorEdge(this.startActor, this.tippyController.sourceActor, edgeType.soft, this.linkDescription)) {
        this.alertService.error('Multiple links cannot be created between subjects', this.alertServiceOptions);
      }
    }
    this.hideTippyController(true);
    this.isLinkingStarted = false;
    this.linkDescription = "";
    this.startActor = null;
  }

  public getPremiumActorsLocation(actor: IActorBase): string {
    ;
    switch (actor?.actorType) {
      case EntityTypes.DiligenciaOrganisation:
        return this.premiumUtils.getPremiumOrgInfosLocation(actor.asDiligenciaOrgInfo);
      case EntityTypes.DiligenciaPerson:
        return this.premiumUtils.dtoToSelectedLanguage(actor?.asDiligenciaPersonInfo?.nationalityDto) ?? "";
    }
  }

  isInGroup(): boolean {
    return (this.tippyController?.sourceNode?.data()?.parent != null);
  }

  removeFromGroup() {
    let parentId = this.tippyController.sourceNode.data().parent;
    let parentNode = this.dashService.findNode(parentId);
    let groupName = parentNode.data().groupname;
    groupName = groupName ? groupName : "Untitled";
    this.tippyController.sourceNode.move({ parent: null });
    this.dashService.addAuditTrail('Subject ' + this.tippyController.sourceActor.name + " removed from group " + groupName);
    if (parentNode?.children().length == 0) {
      parentNode.remove();
      this.dashService.addAuditTrail('Group ' + groupName + " removed from risk map.");
    }
    this.hideTippyController(true);
  }

  createGroup() {
    if (this.tippyController.expandGroupCreate) {
      this.dashService.addGroupNode(this.tippyController.sourceNode, this.tippyController.groupName);

      this.hideTippyController(true);
    }
    else {
      this.tippyController.expandGroupCreate = true;
      this.tippyController.groupName = "";
    }
  }

  completePersonNodeMerge() {
    // Only copy data in to empty fields
    this.tippyController.targetActor.infoObject.address = this.tippyController.targetActor.infoObject.address ?? this.tippyController.sourceActor.infoObject.address;
    this.tippyController.targetActor.infoObject.gender = this.tippyController.targetActor.infoObject.gender ?? this.tippyController.sourceActor.infoObject.gender;
    this.tippyController.targetActor.infoObject.nationality = this.tippyController.targetActor.infoObject.nationality ?? this.tippyController.sourceActor.infoObject.nationality;
    this.tippyController.targetActor.infoObject.dateOfBirth = this.tippyController.targetActor.infoObject.dateOfBirth ?? this.tippyController.sourceActor.infoObject.dateOfBirth;
    this.tippyController.targetActor.infoObject.jurisdiction = this.tippyController.targetActor.infoObject.jurisdiction ?? this.tippyController.sourceActor.infoObject.jurisdiction;
    this.tippyController.targetActor.infoObject.jurisdictionName = this.tippyController.targetActor.infoObject.jurisdictionName ?? this.tippyController.sourceActor.infoObject.jurisdictionName;

    this.tippyController.targetActor.isUBO = this.tippyController.targetActor.isUBO || this.tippyController.sourceActor.isUBO;
    this.tippyController.targetActor.isActive = this.tippyController.targetActor.isActive || this.tippyController.sourceActor.isActive;

    // Go through and copy the edges
    this.cy.nodes("[id = '" + this.tippyController.sourceActor.id + "']").connectedEdges().forEach((edge: cytoscape.EdgeSingular) => {
      var actorEdge = edge.data("actorEdge");
      if (actorEdge.sourceActor.id == this.tippyController.sourceActor.id) {
        this.dashService.addActorEdge(this.tippyController.targetActor, actorEdge.targetActor, actorEdge.type, actorEdge.description);
      }
      else if (actorEdge.targetActor.id == this.tippyController.sourceActor.id) {
        this.dashService.addActorEdge(actorEdge.sourceActor, this.tippyController.targetActor, actorEdge.type, actorEdge.description);
      }
    });

    this.dashService.mergeActor(this.tippyController.sourceActor, this.tippyController.targetActor, this.tippyController.reason, this.tippyController.sourceNode);

    this.hideTippyController(true);
  }

  completeCompanyNodeMerge() {
    if (this.tippyController.sourceActor instanceof DummyCompanyActor) {
      this.mergeFromDummyCompany(this.tippyController.sourceActor, this.tippyController.targetActor);
      let removedNode = this.tippyController.sourceNode.remove();
      this.dashService.removedCollection.push(removedNode);
    }
    else if (this.tippyController.targetActor instanceof DummyCompanyActor) {
      this.mergeFromDummyCompany(this.tippyController.targetActor, this.tippyController.sourceActor);
      let removedNode = this.tippyController.targetNode.remove();
      this.dashService.removedCollection.push(removedNode);
    }
    this.hideTippyController(true);
  }

  private mergeFromDummyCompany(source: IActorBase, target: IActorBase) {
    this.cy.nodes("[id = '" + source.id + "']").connectedEdges().forEach((edge: cytoscape.EdgeSingular) => {
      var actorEdge = edge.data("actorEdge");
      if (actorEdge.sourceActor.id == source.id) {
        this.dashService.addActorEdge(target, actorEdge.targetActor, actorEdge.type, actorEdge.description);
      }
      else if (actorEdge.targetActor.id == source.id) {
        this.dashService.addActorEdge(actorEdge.sourceActor, target, actorEdge.type, actorEdge.description);
      }
    });
    target.clientId = target.clientId ?? source.clientId;
    target.profileId = target.profileId ?? source.profileId;
    this.dashService.addAuditTrail('Merged node ' + source.name + " with " + target.name);
    this.setInvestigation(target);
  }

  cancelNodeMerge() {
    this.hideTippyController(true);
  }

  getFullAddress(address: any) {
    if (address) {
      return address;
    }
    return "";
  }

  //To download a graph as a PNG image
  downloadGraph() {
    const data = this.dashService.cytoscapeObject.png({
      bg: '#ffffff'
    });
    const filename = !this.dashService.investigationName || this.dashService.investigationName === "" ? 'graph' : this.dashService.investigationName;
    const a = document.createElement("a");
    a.href = 'data:image/png;' + data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
    }, 0);
  }
}