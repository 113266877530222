import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UtilityService } from "src/app/utilitities/utilities";
import { ClientResult, IdvUserProfile } from 'src/nswag';

@Component({
    selector: 'app-idv-detail-base',
    template: ""
})
export class IdvDetailBaseComponent implements OnInit, OnChanges {

    @Input() idvProfile: IdvUserProfile;
    @Input() showButtons = true;
    @Input() clientResult: ClientResult;
    @Output() changeProfile = new EventEmitter<IdvUserProfile>(); 
  
    public name: string;
    public profileImageId: string;
    public country: string;
    public address: string;
    public nationality: string;
    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.clearState();       
        this.name = this.idvProfile?.fullName;
        if (this.idvProfile?.documents?.length > 0) {
            for(let document of this.idvProfile.documents) {
                if (document.name.startsWith("Image")) {
                    this.profileImageId = document.id;
                }
            }            
        }
        
        this.address = this.idvProfile?.address?.fullAddress;
        this.country = UtilityService.getCountryFromCode(this.idvProfile?.country);
        this.nationality = UtilityService.getNationalityFromCode(this.idvProfile?.nationalityCode);
    }

    clearState() {
        this.profileImageId = null;
        this.name = null;
        this.address = null;
        this.country = null;    
        this.nationality = null;
    }
}