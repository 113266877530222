import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { ProfileService } from 'src/app/profile.service';
import { AlertService } from 'src/app/_alert';
import { CreatePaymentSessionCommand, ProductDetail, SelfServePaymentProduct, SendEmailCommand, UserInteractionClient } from 'src/nswag';
import { SelfServiceService } from '../services/self-service.service';
import { FeatureState } from 'src/app/enums';

export enum AdvertState {
  CHECKING = 'Checking',
  INVESTIGATIONAD = 'InvestigationAd',
  SCREENAD = 'ScreenAd',
  MONITORAD = 'MonitorAd',
  OUTOFCREDITAD = 'OutOfCreditAd',
  IDVAD = 'IdvAd',
  ASSESSMENTAD = 'AssessmentAd',
  APISETTINGSAD = 'ApiSettingsAd',
  ASSESSMENTSETTINGSAD = 'AssessmentSettingsAd',
  ADDCREDITAD = 'AddCreditAd',
  INTELLIGENCEAD = 'IntelligenceAd',
}

export enum Currency {
  POUNDS = 'gbp',
  DOLLARS = 'usd'
}

@Component({
  selector: 'app-advert-dialog',
  templateUrl: './advert-dialog.component.html',
  styleUrls: ['./advert-dialog.component.scss']
})
export class AdvertDialogComponent implements OnInit {

  @Input() advertCriteria: string;

  AdvertState = AdvertState;
  currentAdState = AdvertState.CHECKING;
  Currency = Currency;
  Products = SelfServePaymentProduct;

  public firstName: string;
  public lastName: string;
  public email: string;
  public isSuccess: boolean;
  public disableAll = false;
  public modal: NgbModalRef;
  public selectedCurrency = Currency.POUNDS;

  constructor(private modalService: NgbModal, public selfServeService: SelfServiceService, public userInteractionClient: UserInteractionClient, 
    private profileService: ProfileService, private alertService: AlertService) { }

  ngOnInit(): void {

    this.selfServeService.initalisePayment();

    if (this.advertCriteria == FeatureState.SEARCH) {
      this.currentAdState = AdvertState.INVESTIGATIONAD;
    }
    else if (this.advertCriteria == FeatureState.SCREEN) {
      this.currentAdState = AdvertState.SCREENAD;
    }
    else if (this.advertCriteria == FeatureState.MONITOR) {
      this.currentAdState = AdvertState.MONITORAD;
    }
    else if (this.advertCriteria == FeatureState.OUTOFCREDITS) {
      this.currentAdState = AdvertState.OUTOFCREDITAD;
    }
    else if(this.advertCriteria == FeatureState.IDV){
      this.currentAdState = AdvertState.IDVAD;
    }
    else if(this.advertCriteria == FeatureState.ASSESSMENT){
      this.currentAdState = AdvertState.ASSESSMENTAD;
    }
    else if(this.advertCriteria == FeatureState.APISETTINGS){
      this.currentAdState = AdvertState.APISETTINGSAD;
    }
    else if(this.advertCriteria == FeatureState.ASSESSMENTSETTINGS){
      this.currentAdState = AdvertState.ASSESSMENTSETTINGSAD;
    }
    else if(this.advertCriteria == FeatureState.INTELLIGENCE){
      this.currentAdState = AdvertState.INTELLIGENCEAD;
    }
    else {
      this.currentAdState = AdvertState.ADDCREDITAD;
    }
  }

  public makeSession(currency: string, product: SelfServePaymentProduct){
    var command = new CreatePaymentSessionCommand();
    var stripeSession = new ProductDetail();
    stripeSession.currency = currency;
    stripeSession.product = product;
    stripeSession.feature = 'Screen'
    command.session = stripeSession;
    
    this.selfServeService.createPaymentSession(command).subscribe(async result => {
      if(result.isSuccess){
        const stripeKey = this.selfServeService.getStripeKey();
        const stripe = loadStripe(stripeKey);
        (await stripe).redirectToCheckout({
          sessionId: result.data
        })
          }
    });
  }

  private resetMessaging() {
    this.isSuccess = null;
  }

  public closeModals() {
    this.resetMessaging?.();

    this.modalService.dismissAll();
  }

  public submitContactRequest(module: string) {
    this.disableAll = true;
    
    let command = new SendEmailCommand();
    command.isAnonymised = false;
    command.actorId = this.profileService.userId;
    command.subject = `${this.profileService.firstName} requested to get in contact with us to discuss their account and ${this.profileService.isSelfServeUser() ? 'credits' : 'modules'}`;
    command.body = `
        ${!this.profileService.isSelfServeUser() ? `Module: ${module}, \n`: ''}
        First Name: ${this.profileService.firstName},\n
        Last Name: ${this.profileService.lastName},\n
        Email: ${this.profileService.email}, \n
    `;

    this.sendEmail(command);
  }

  private sendEmail(command: SendEmailCommand) {
    this.userInteractionClient.sendEmail(command).subscribe(result => {
      this.modalService.dismissAll();
      this.disableAll = false;
      this.alertService.success('Your request to get in contact with us has been sent successfully', { autoClose: true, keepAfterRouteChange: false });      
    },
    (error) => {
      this.disableAll = false;
      this.alertService.error('Unfortunately, your request failed. Please try again.', { autoClose: true, keepAfterRouteChange: true });
    });
  }
}
function Stripe(arg0: string) {
  throw new Error('Function not implemented.');
}