<div class="container overflow-auto">
  <div class="modal-header">
    <h4 class="col">{{title}}</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <app-problem-details></app-problem-details>
  <div class="modal-body">
    <div class="container">
      <div *ngIf="isAdding">
        <!-- Company or Person -->
        <div class="row">
          <div class="input-group">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="officer" name="searchType" value="0" [checked]="!isBusiness" (change)="setClientType()">
              <label class="form-check-label" for="officer">Individual</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="company" name="searchtype" value="1" [checked]="isBusiness" (change)="setClientType()">
              <label class="form-check-label" for="company">Company</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isBusiness then companyEditorBlock; else personEditorBlock"></div>
    </div>
    <div class="float-end p-2">
      <button class="btn m-1 bg-secondary text-light" (click)="dismissModal()" id="uniqueCancelEdit">Cancel</button>
      <button [disabled]="hasModelErrors()" class="btn m-1 bg-primary text-light" id="uniqueAddClient" (click)="saveClient()">{{buttonTitle}}</button>
    </div>
  </div>
</div>

<ng-template #companyEditorBlock>
  <div class="mt-2 d-flex">
    <div class="row required-input">
      <label class="required-input">Company Name</label>
    </div>
    <small class="required-label">(Required)</small>
  </div>
  <div class="row">
    <div class="input-group required-name">
      <input type="text" required name="name" class="form-control mb-2" [(ngModel)]="business.name" #name="ngModel">
    </div>
    <div *ngIf="(name.invalid) && (!name.pristine)" class="row">
      <div *ngIf="name.errors?.required" class="form-control alert alert-danger">
        Company Name is required
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <label>Jurisdiction</label>
  </div>
  <div class="row">
    <div class="input-group client-nationality">
      <input id="jurisdiction" 
      [(ngModel)]="jurisdiction" 
      [ngbTypeahead]="countryTypeAhead" 
      class="form-control mb-2" 
      (focus)="onJurisdictionFocus($event)"
      (blur)="onJurisdictionBlur($event)"
      name="checkJurisdiction"
      #checkJurisdiction="ngModel"/>
    </div>
    <div *ngIf="(jurisdiction && !isJurisdictionValid()) && (checkJurisdiction.dirty || checkJurisdiction.touched) && !jurisdictionFocus" class="row">
      <div class="form-control alert alert-danger">
        Please provide a valid country for Jurisdiction or leave it empty
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Unique Client Reference ID</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" id="uniqueCliEdit" [(ngModel)]="business.clientRef" (change)="checkBusinessClientExists(business.clientRef)">
      </div>
      <div *ngIf="clientExists" class="row">
        <div class="form-control alert alert-danger">
          <small> A client with this ID already exists.</small>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="mt-2">
        <label>Risk Level</label>
      </div>
      <div class="input-group">
        <div class="input-group">
          <select class="form-select form-control"  id="uniqueCliEditOption" [(ngModel)]="business.riskLevel">
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Tags</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" id="uniqueClientEditTag">
      <app-tags [(tagsInput)]="business.tags" [editable]=true></app-tags>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="addressEditorBlock"></ng-container>
</ng-template>

<ng-template #personEditorBlock>
  <div class="mt-2 d-flex">
    <div class="row required-input">
      <label class="required-input">Individual's Name</label>
    </div>
    <small class="required-label">(Required)</small>
  </div>
  <div class="row">
    <div class="input-group required-name">
      <input type="text" required name="name"  id="uniqueClientInd" class="form-control mb-2" [(ngModel)]="individual.name" #name="ngModel">
    </div>
    <div *ngIf="(name.invalid || (!this.isIndividualNameValid())) && (!name.pristine)" class="row">
      <div *ngIf="name.errors?.required" class="form-control alert alert-danger">
        Name is required.
      </div>
      <div *ngIf="!this.isIndividualNameValid() && this.individual.name" class="form-control alert alert-danger">
        Only alphabetical characters allowed.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Unique Client Reference ID</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control"  id="uniqueCliEditTwo" [(ngModel)]="individual.clientRef" (change)="checkIndividualClientExists(individual.clientRef)">
      </div>
      <div *ngIf="clientExists" class="row">
        <div class="form-control alert alert-danger">
          <small> A client with this ID already exists.</small>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="mt-2">
        <label>Risk Level</label>
      </div>
      <div class="input-group">
        <select class="form-select form-control" id="uniqueCliEditTwoOption" [(ngModel)]="individual.riskLevel">
          <option value="High">High</option>
          <option value="Medium">Medium</option>
          <option value="Low">Low</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Date of Birth</label>
      </div>
      <div id="uniqueClientEditDate">
        <app-date-picker (dataEvent)='dateChanged($event)' [dateOfBirth]="individual.dateOfBirth"></app-date-picker>
      </div>
    </div>
    <div class="col">
      <div class="mt-2">
        <label>Gender</label>
      </div>
      <div class="input-group">
        <select class="form-select form-control" id="uniqueClientEditGender" [(ngModel)]="individual.gender">
          <option value=""></option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <label>Nationality / Country of Residence</label>
  </div>
  <div class="row">
    <div class="input-group client-nationality">
      <input 
      id="nationality"
      [(ngModel)]="nationality" 
      [ngbTypeahead]="nationalityTypeAhead" 
      class="form-control mb-2" 
      (focus)="onNationalityFocus($event)"
      (blur)="onNationalityBlur($event)"
      name="checkNationality"
      #checkNationality="ngModel"/>
    </div>
    <div *ngIf="(nationality && !isNationalityValid()) && (checkNationality.dirty || checkNationality.touched) && !nationalityFocus" class="row">
      <div class="form-control alert alert-danger">
        Valid Nationality is required.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Tags</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" id="uniqueClientEditTwoTag">
      <app-tags [(tagsInput)]="individual.tags" [editable]=true></app-tags>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="addressEditorBlock"></ng-container>
</ng-template>

<ng-template #addressEditorBlock>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Address line 1</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" id="uniqueAddress" [(ngModel)]="editclient.client.address.line1" #name="ngModel">
      </div>
    </div>
    <div class="col">
      <div class="mt-2">
        <label>Address line 2</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" id="uniqueAddressTwo" [(ngModel)]="editclient.client.address.line2">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>City/Town</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" id="uniqueCity" [(ngModel)]="editclient.client.address.city">
      </div>
    </div>
    <div class="col">
      <div class="mt-2">
        <label>County/State</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" id="uniqueCounty" [(ngModel)]="editclient.client.address.county">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Zip/Postcode</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" id="uniqueZip" [(ngModel)]="editclient.client.address.postcode">
      </div>
    </div>
    <div class="col">
      <div class="mt-2">
        <label>Country</label>
      </div>
      <div class="input-group  client-nationality">
        <input [(ngModel)]="addressCountry" 
        [ngbTypeahead]="addressCountryTypeAhead" 
        class="form-control" 
        (focus)="onAddressCountryFocus($event)"
        (blur)="onAddressCountryBlur($event)"
        name="checkAddressCountry"
        #checkAddressCountry="ngModel"/>
      </div>
    </div>
    <div *ngIf="(addressCountry && !isAddressCountryValid()) && (checkAddressCountry.dirty || checkAddressCountry.touched) && !checkAddressCountryFocus" class="row">
      <div class="form-control alert alert-danger mt-2">
        Please provide a valid country or leave it empty.
      </div>
    </div>
  </div>
  
</ng-template>

<app-loader [show]="isBusy"></app-loader>