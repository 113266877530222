import { Injectable } from "@angular/core";
import { StatusOutcome } from "src/nswag";

@Injectable({ providedIn: 'root' })
export class IdvStatusCodeService {
  public static statusCodeToClass(status: StatusOutcome): string {
    switch (status) {
      // ACCEPTED
      case StatusOutcome.ACCEPTED: return "bg-success";
      // ANALYSING
      case StatusOutcome.ANALYSING: return "bg-secondary";
      // CREATED
      case StatusOutcome.CREATED: return "bg-secondary";
      // DECLINED
      case StatusOutcome.DECLINED: return "bg-danger";
      // FAILED
      case StatusOutcome.FAILED: return "bg-warning";
      // WAITING
      case StatusOutcome.WAITING: return "bg-warning";
      // TIMEOUT
      case StatusOutcome.TIMEOUT: return "bg-warning";
      // OTHER
      case StatusOutcome.OTHER: return "bg-warning";
      // OPERATOR_REQUIRED
      case StatusOutcome.OPERATOR_REQUIRED: return "bg-warning";
      // CANCELLED
      case StatusOutcome.CANCELLED: return "bg-warning";
      // investigate other codes
      default: return "bg-warning";
    }
  }
}