<div id="monitor-pep">
    <p class="alert alert-info my-3" *ngIf="(isByAssociation || isPEP || isPersonOfInterest) && evidences?.length > 0">
        Selecting <strong>View URL Evidence</strong> will direct you to the link. Please note that the source may have expired and is provided by a third-party. Select <strong>View PDF Evidence</strong> to access the PDF version
    </p>
    <h5 *ngIf="incHeader">Political Exposure (PEP)</h5>
    <div *ngIf="isPEP; else notPEPElseBlock">
        <div *ngIf="isCurrent" class="row">
            <p class="alert alert-danger">
                {{name}} is currently a <span class="dotted-text" id="pepLevelElement">{{PEPLevelDescription}}.</span>
            </p>
            <h5>Current exposure</h5>
            <ng-container [ngTemplateOutlet]="displayPEP" [ngTemplateOutletContext]="{peplist: profile.pepEntries.current}"></ng-container>
        </div>
        <div *ngIf="isPrevious" class="row pt-4">
            <p *ngIf="!isCurrent" class="alert alert-warning">
                {{name}} is a former <span class="dotted-text" id="pepLevelElement">{{PEPLevelDescription}}.</span>
            </p>
            <h5>Past exposure</h5>
            <ng-container [ngTemplateOutlet]="displayPEP" [ngTemplateOutletContext]="{peplist: profile.pepEntries.former}"></ng-container>
        </div>
        <div *ngIf="isPersonOfInterest" class="row">
            <p class="alert alert-warning">
                {{name}} is a person of interest.<span class="dotted-text" id="pepLevelElement"></span>
            </p>
            <ng-container [ngTemplateOutlet]="displayPOI" [ngTemplateOutletContext]="{poilist: profile.poiEntries}"></ng-container>
        </div>
    </div>

    <ng-template #notPEPElseBlock>
        <div *ngIf="isByAssociation; else noPEP">
            <p class="alert alert-warning">
                {{name}} <span class="dotted-text" id="pepLevelElement">is associated with other PEPs.</span>
            </p>
            <!-- <ng-container [ngTemplateOutlet]="displayPEP" [ngTemplateOutletContext]="{peplist: profile.pepByAssociationEntries}"></ng-container> -->
        </div>
    </ng-template>

    <ng-template #noPEP>
        <div class="col alert alert-info">{{name}} is not Politically Exposed (PEP).</div>
    </ng-template>

    <ng-template #displayPEP let-peplist="peplist">
        <div class="peplist" *ngFor="let pep of peplist; index as i">
            <div class="row" id="left-card">
                <div class="col-3">
                    <label class="displaylabel-style">Position:</label>
                </div>
                <div class="col-9">
                    <span *ngIf="incHeader" class="displaydetail-style">
                        <app-readmore [summary]="pep?.position" [sliceLength]="20" ></app-readmore>
                    </span>
                    <span *ngIf="!incHeader" class="displaydetail-style">
                        {{pep?.position}}
                    </span>
                    <br *ngIf="pep?.position">
                    <span class="displaydetail-style">{{pep?.segment}}</span><br *ngIf="pep?.segment">
                    <span class="displaydetail-style">{{getCountry(pep?.countryIsoCode)}}</span>
                </div>
            </div>
            <div *ngIf="pep?.dateFromIso" class="row" id="left-card">
                <div class="col-3">
                    <label class="displaylabel-style">Date:</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">{{pep?.dateFromIso}}
                        <span *ngIf="pep?.dateToIso"> - {{pep?.dateToIso}}</span>
                    </span>
                </div>
            </div>
            <mon-evidences [clientId]="clientId" [profile]="profile" [evidenceIds]="pep.evidenceIds" [evidences]="profile.evidences" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-evidences>
        </div>
    </ng-template>

    <ng-template #displayPOI let-poilist="poilist">
        <div class="poilist" *ngFor="let entry of poilist; let pos=index">
            <div class="row">
                <div class="col">
                    <h5>Positions Held</h5>
                    <span>{{entry.category ?? "Uncategorised"}}</span>
                </div>
            </div>
           <div *ngFor="let poi of entry.positions">
                <div class="row" id="left-card">
                    <div class="col-3">
                        <label class="displaylabel-style">Position:</label>
                    </div>
                    <div class="col-9">
                        <span *ngIf="incHeader" class="displaydetail-style">
                            <app-readmore [summary]="poi?.position" [sliceLength]="20" ></app-readmore>
                        </span>
                        <span *ngIf="!incHeader" class="displaydetail-style">
                            {{poi?.position}}
                        </span>
                        <br *ngIf="poi?.position">
                        <span class="displaydetail-style">{{poi?.segment}}</span><br *ngIf="poi?.segment">
                        <span class="displaydetail-style">{{getCountry(poi?.countryIsoCode)}}</span>
                    </div>
                </div>
                <div *ngIf="poi?.dateFromIso" class="row" id="left-card">
                    <div class="col-3">
                        <label class="displaylabel-style">Date:</label>
                    </div>
                    <div class="col-9">
                        <span class="displaydetail-style">{{poi?.dateFromIso}}
                            <span *ngIf="poi?.dateToIso"> - {{poi?.dateToIso}}</span>
                        </span>
                    </div>
                </div>
            </div>
            <br>
            <div class="evidences-container" *ngIf="entry.evidenceIds">
                <mon-evidences [clientId]="clientId" [allowDiscounting]="false" [profile]="profile" [evidenceIds]="entry.evidenceIds" [evidences]="profile.evidences" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-evidences>
            </div>
        </div>
    </ng-template>
</div>
