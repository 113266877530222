<div class="row pt-4">
    <h5 class="p-0">Disqualified Directorships</h5>
    <div *ngIf="ddEntries?.length >0; else noentries">
        <p class="alert alert-danger">
            {{name}} has been reported as a disqualified director.
        </p>
        <div *ngFor="let entry of ddEntries">
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Case Ref.', value: entry.caseReference}"></ng-container>
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Reason', value: entry.reason}"></ng-container>
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Company', value: entry.conduct}"></ng-container>
            <div *ngIf="checkExists(entry.dateFromIso)" class="row" id="left-card">
                <div class="col-3">
                    <label class="displaylabel-style">Dates</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">{{entry?.dateFromIso}}
                        <span *ngIf="entry?.dateToIso"> - {{entry?.dateToIso}}</span>
                    </span>
                </div>
            </div>
            <div class="row">
                <mon-evidences [clientId]="clientId" [profile]="profile" [evidenceIds]="entry.evidenceIds" [evidences]="evidences" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-evidences>
            </div>
        </div>
    </div>
</div>

<ng-template #displayRow let-label="label" let-value="value">
    <div *ngIf="checkExists(value)" class="row" id="left-card">
        <div class="col-3">
            <label class="displaylabel-style">{{label}}</label>
        </div>
        <div class="col-9">
            {{value}}
        </div>
    </div>

</ng-template>

<ng-template #noentries>
    <div class="col alert alert-info">No disqualifications have been found.</div>
</ng-template>

