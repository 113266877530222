import { Component } from '@angular/core';
import { IdvUserProfile, MonitoringClient, ScreeningClient, StatusOutcome, ZignSecClient } from 'src/nswag';
import { IdvDetailBaseComponent } from '../idv-detail-base.component';
import tippy from 'tippy.js';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { MonitorLinkService } from 'src/app/services/monitor-link.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { ScreeningService } from 'src/app/screening/services/screening.service';
import { ProfileService } from 'src/app/profile.service';
import { IdvClientDetailComponent } from '../../idv-client-detail/idv-client-detail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdvStatusCodeService } from '../../status-codes.service';

@Component({
  selector: 'app-idv-profile-detail',
  templateUrl: './idv-profile-detail.component.html',
  styleUrls: ['./idv-profile-detail.component.scss']
})
export class IdvProfileDetailComponent extends IdvDetailBaseComponent {

  public isBusy = false;

  constructor(private dashService: DashboardService, private router: Router, private alertService: AlertService, private monitorLinkService: MonitorLinkService, private screeningService: ScreeningService, private screeningClient: ScreeningClient, private monitoringClient: MonitoringClient, private modalService: NgbModal, private idvClient: ZignSecClient, private profileService: ProfileService) {
    super();
  }

  public statusCodeToClass(status: StatusOutcome): string {
    return IdvStatusCodeService.statusCodeToClass(status);
  }

  public onBadgeHover(idvProfile: IdvUserProfile) {
    if (idvProfile.rejectionReason != null) {
      var badgeId = "badge-" + idvProfile.id;
      let el: any = document.getElementById(badgeId);
      if (el) {
        let tippyCtrl = el._tippy;
        if (!tippyCtrl) {
          tippy('#' + badgeId, {
            allowHTML: true,
            content: idvProfile.rejectionReason
          });
        }
      }
    }
  }

  public viewInvestigation() {
    if (!this.profileService.searchEnabled()) {
      this.alertService.warn("Sorry, Search is not enabled for this account. Please contact support if you want to try it out!", { autoClose: true });
      return;
    }
    this.dashService.setIDVPerson(this.idvProfile);
    this.router.navigate(['search/dashboard']);
  }

  public isMonitored(): boolean {
    return this.clientResult?.client?.inMonitoring;
  }

  public isScreened(): boolean {
    return this.clientResult && this.clientResult.client != null;
  }

  public viewClient() {
    if (this.isMonitored()) {
      this.viewMonitoringClientDetailsModal();
    }
    else if (this.isScreened()) {
      this.viewScreeningClientDetailsModal();
    }
  }

  private viewScreeningClientDetailsModal() {
    this.screeningClient.getIndividualMatchedProfile(this.clientResult?.client?.id).subscribe(result => {
      if (result.isSuccess) {
        this.isBusy = false;
        let ref = this.modalService.open(IdvClientDetailComponent);
        ref.componentInstance.idvProfile = this.idvProfile;
        ref.componentInstance.matchedProfile = result.data;
        ref.componentInstance.clientResult = this.clientResult;
        ref.componentInstance.ngOnChanges(null);
      }
    });
    this.isBusy = true;
  }

  private viewMonitoringClientDetailsModal() {
    this.monitoringClient.getIndividualMatchedProfile(this.clientResult?.client?.id).subscribe(result => {
      if (result.isSuccess) {
        this.isBusy = false;
        let ref = this.modalService.open(IdvClientDetailComponent);
        ref.componentInstance.idvProfile = this.idvProfile;
        ref.componentInstance.matchedProfile = result.data;
        ref.componentInstance.clientResult = this.clientResult;
        ref.componentInstance.ngOnChanges(null);
      }
    });
    this.isBusy = true;
  }

  public addToScreening() {
    if (this.isScreened()) {
      // Cant add twice
      return;
    }
    if (!this.profileService.screeningEnabled()) {
      this.alertService.warn("Sorry, Screening is not enabled for this account. Please contact support if you want to try it out!");
      return;
    }
    this.screeningService.launchScreeningFromIDV(this.idvProfile).subscribe(result => {
      this.updateIDVProfile(result?.client?.id);
    });
  }

  public addToMonitoring() {
    if (this.isMonitored()) {
      // Cant add twice
      return;
    }

    if (!this.profileService.monitoringEnabled()) {
      this.alertService.warn("Sorry, Monitoring is not enabled for this account. Please contact support if you want to try it out!");
      return;
    }
    if (this.isScreened()) {
      this.clientResult.client.address = UtilityService.convertToAddress2(this.idvProfile.address);
      this.screeningService.addToMonitoring(this.clientResult).subscribe(result => {
        if (result.isSuccess) {
          this.clientResult = result.data;
          this.alertService.success(this.idvProfile.fullName + " has been added to ongoing monitoring.", { autoClose: true });
        }
        else {
          this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
        }
      });
    }
    else {
      this.monitorLinkService.addIDVToMonitoring(this.idvProfile.id, this.idvProfile, null, "Added from IDV").subscribe(r => {
        if (r) {
          this.updateIDVProfile(r.client.id);
          this.alertService.success(this.idvProfile.fullName + " has been added to ongoing monitoring.", { autoClose: true });
        }
        else {
          this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
        }
      });
    }
  }

  private updateIDVProfile(clientId: string) {
    if (clientId == null) {
      return;
    }
    this.idvClient.linkProfileToClient(this.idvProfile.id, clientId).subscribe(result => {
      if (result.isSuccess) {
        this.clientResult = result.data;
      }
    });
  }

}

