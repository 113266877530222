<div class="row pt-4">
    <h5 class="p-0">Insolvency</h5>
    <div *ngIf="insEntries?.length >0; else noentries">
        <p class="alert alert-danger">
            {{name}} has been reported as being insolvent.
        </p>
        <div *ngFor="let entry of insEntries">
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Type', value: entry.type, ignore: ['Insolvency Type']}"></ng-container>
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Number', value: entry.insolvencyIdNumber}"></ng-container>
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Solicitor', value: entry.solicitor}"></ng-container>
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Court', value: entry.court}"></ng-container>
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Petitioner', value: entry.petitioner}"></ng-container>
            <ng-container [ngTemplateOutlet]="displayRow" [ngTemplateOutletContext]="{label: 'Issue date', value: entry.gazetteIssueDateIso}"></ng-container>
            <div *ngIf="checkExists(entry.insolvencyStartDateIso)" class="row" id="left-card">
                <div class="col-3">
                    <label class="displaylabel-style">Dates</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">{{entry?.insolvencyStartDateIso}}
                        <span *ngIf="entry?.insolvencyEndDateIso"> - {{entry?.insolvencyEndDateIso}}</span>
                    </span>
                </div>
            </div>
            <div class="row">
                <mon-evidences [clientId]="clientId" [profile]="profile" [evidenceIds]="entry.evidenceIds" [evidences]="evidences" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-evidences>
            </div>
        </div>
    </div>
</div>

<ng-template #displayRow let-label="label" let-value="value" let-ignore="ignore">
    <div *ngIf="checkExists(value,ignore)" class="row" id="left-card">
        <div class="col-3">
            <label class="displaylabel-style">{{label}}</label>
        </div>
        <div class="col-9">
            {{value}}
        </div>
    </div>

</ng-template>

<ng-template #noentries>
    <div class="col alert alert-info">No insolvency cases have been found.</div>
</ng-template>


