import { Injectable } from '@angular/core';
import { Address, Address2, BusinessAlias, Gender, IndividualAlias, IndividualClient, IndividualMatch } from 'src/nswag';
import { CountriesService } from '../utils/countries.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() { }

  static getUpdateSettingsMessageText(monitorFeature: boolean, searchFeature: boolean, screenFeature: boolean){
    let message = "This will be immediately avaliable when screening new records and will be applied when the next monitoring process runs.";
    if ((searchFeature || screenFeature) && !monitorFeature){
     // Search/Screen & no Monitor
     message = "This will be immediately avaliable when screening new records.";
    }
    else if ((!searchFeature || !screenFeature) && monitorFeature){
     // Monitor only
     message = "This will be applied when the next monitoring process runs.";
    }
    return message;
  }

  static getIndividualFullName(name: string, firstName: string, otherName: string, lastName: string): string {
    if (name) return name;
    else return [firstName, otherName, lastName].filter(Boolean).join(" ");
  }

  static getIndividualName(result: any) {
    return [result.firstName, result.middleName, result.lastName].filter(Boolean).join(" ");
  }

  static hasAddress(addresses: Address2[]): boolean {
    return (addresses && addresses.length > 0);
  }

  static getLongestAddress(addresses: Address2[]): string {
    if (addresses?.length > 0) {
      let returnAddress: string = "";
      for (let add of addresses) {
        let sadd = [add.line1, add.line2, add.city, add.county, this.getCountryFromCode(add.countryIsoCode), add.postcode].filter(Boolean).join(", ");
        if (sadd?.length > returnAddress?.length) {
          returnAddress = sadd;
        }
      }
      return returnAddress;
    }
    return undefined;
  }

  static findLongestAddress(addresses: Address2[]): number {
    let result: number = -1;
    if (addresses?.length > 0) {
      let prevAddress: string = "";
      for (let i = 0; i < addresses.length; i++) {
        let sadd = [addresses[i].line1, addresses[i].line2, addresses[i].city, addresses[i].county, this.getCountryFromCode(addresses[i].countryIsoCode), addresses[i].postcode].filter(Boolean).join();
        if (sadd?.length > prevAddress?.length) {
          result = i;
          prevAddress = sadd;
        }
      }
    }
    return result;
  }

  static getAddress(add: Address2): string {
    if (add) {
      return [add.line1, add.line2, add.city, add.county, this.getCountryFromCode(add.countryIsoCode), add.postcode].filter(Boolean).join(", ");
    }
    return undefined;
  }

  static getCountry(addresses: Address2[]): string {
    if (this.hasAddress(addresses)) {
      return this.getCountryFromCode(addresses[0]?.countryIsoCode);
    }
    return "Data not available";
  }

  static getCountryFromCode(code: string): string {
    if (code) {
      return CountriesService.getJurisdictionName(code);
    }
    return null;
  }

  static getCountriesListFromCodes(countries: string[]): string {
    if (countries?.length == 0) {
      return "";
    }
    let countryList: string = "";
    let count: number = 0;
    for (let c of countries) {
      if (c?.length > 0) {
        let co = UtilityService.getCountryFromCode(c);
        countryList += count > 0 ? ", " + co : co;
        count++;
      }
    }
    return countryList;
  }

  static isPEP(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.PEP || item == DataSets.PEPCURRENT) {
        return true;
      }
    }
    return false;
  }

  static isFormerPEP(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.PEPFORMER) {
        return true;
      }
    }
    return false;
  }

  static isSOE(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.SOE || item == DataSets.SOECURRENT) {
        return true;
      }
    }
    return false;
  }

  static isFormerSOE(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.SOEFORMER) {
        return true;
      }
    }
    return false;
  }

  static isSanction(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.SAN || item == DataSets.SANCURRENT) {
        return true;
      }
    }
    return false;
  }

  static isFormerSanction(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.SANFORMER) {
        return true;
      }
    }
    return false;
  }
  static isLawEnforcement(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.DD || item == DataSets.INS || item == DataSets.REL) {
        return true;
      }
    }
    return false;
  }

  static hasAdverseMedia(dataSet: string[]) {
    for (let item of dataSet) {
      if (item == DataSets.RRE) {
        return true;
      }
    }
    return false;
  }

  static getIndividualAliasList(aliases: IndividualAlias[]) {
    let response: string = "";
    if (aliases?.length > 0) {
      let count: number = 0;
      for (let alias of aliases) {
        let name: string = UtilityService.getIndividualName(alias);
        if (name) {
          if (count > 0) {
            response += ", ";
          }
          count++;
          response += name;
        }
      }
    }
    return response;
  }

  static getBusinessAliasList(aliases: BusinessAlias[]): string[] {
    let response: string[] = [];
    if (aliases?.length > 0) {
      let count: number = 0;
      for (let alias of aliases) {
        if (alias?.alias) {
          let al = alias?.alias;
          if (alias?.type?.length > 0) {
            al += " (" + alias.type + ")";
          }
          response.push(al);
        }
      }
    }
    return response;
  }

  static convertToAddress2(address: Address): Address2 {
    let address2: Address2;
    if (address) {
      if (address.addressLine1) {
        address2 = new Address2({
          //addressType
          city: address.locality,
          countryIsoCode: address.country,
          county: address.countyOrState,
          countyAbbrev: "",
          line1: address.addressLine1,
          line2: address.addressLine2,
          postcode: address.postOrZipCode
        })
      }
      else if (address.fullAddress) {
        var countryCode = address.country;
        var postcode = null;
        var county = null;
        var city = null;
        var street1 = null;
        var street2 = null;

        // address segments
        // some data have line feeds as separators, while others have ','
        var fullAddress = address.fullAddress;

        // remove country name from full address if it has and we already know the code
        if (countryCode) {
          var countryName = UtilityService.getCountryFromCode(countryCode);
          fullAddress = fullAddress.replaceAll(countryName, '');
        }

        fullAddress = fullAddress.replaceAll('\n', ',');

        let parts = fullAddress.split(',');

        // if it's a single line address without delimitter
        if (parts.length == 1) {
          parts = fullAddress.split(' ');
          parts = parts.reverse();
          // find country part          
          if (!countryCode) {
            parts.findIndex((v, i, o) => {
              var code = this.getCodeFromCountry(v.trim());
              if (code) {
                countryCode = code;
                parts.splice(i, 1);
                return true;
              }
              return false;
            });
          }
          // find post code part
          parts.findIndex((v, i, o) => {
            const ukPostCodeMatch = v.trim().match(/^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/gmi);
            if (ukPostCodeMatch) {
              postcode = ukPostCodeMatch[0];
              countryCode = countryCode ?? 'GB';
              parts.splice(i, 1);
              return true;
            }
            const usZipCodeMatch = v.trim().match(/(^\d{2,5}$)|(^\d{9}$)|(^\d{2,5}-\d{3,4}$)/gmi);
            if (usZipCodeMatch) {
              postcode = usZipCodeMatch[0];
              countryCode = countryCode ?? 'US';
              parts.splice(i, 1);
              return true;
            }
            const canadianPostCodeMatch = v.trim().match(/([A-Z]\d[A-Z]\s?\d[A-Z]\d)/gmi);
            if (canadianPostCodeMatch) {
              postcode = canadianPostCodeMatch[0];
              countryCode = countryCode ?? 'CA';
              parts.splice(i, 1);
              return true;
            }
            return false;
          });
          // find state part
          parts.findIndex((v, i, o) => {
            var stateMatch = v.match(/[A-zÀ-ú]+/gmi);
            if (stateMatch) {
              var state = stateMatch[0];
              var countries = CountriesService.getJurisdictionSubSet(state);
              countries.findIndex((value, index, obj) => {
                var lowerValue = value.toLowerCase();
                if (lowerValue.indexOf(state.toLowerCase()) >= 0) {
                  county = state;
                  return true;
                }
                return false;
              });
              if (county) {
                parts.splice(i, 1);
                return true;
              }
            }
            return false;
          });

          parts = parts.reverse();

          // find city part
          if (parts.length >= 3) {
            city = parts[parts.length - 1];
            parts.splice(parts.length - 1, 1);
          }
          // find second street part
          if (parts.length >= 2) {
            street2 = parts[parts.length - 1];
            parts.splice(parts.length - 1, 1);
          }
          // the remaining parts should be the first line of address
          if (parts.length >= 1) {
            street1 = parts.join(' ');
            parts.splice(0);
          }

          // no more processing
          parts = [];
        }

        // find country name in the address
        // country name likely to be at the end of address
        if (!countryCode) {
          for (var i = parts.length - 1; i >= 0; i--) {
            var countryName = parts[i].trim();
            var code = this.getCodeFromCountry(countryName);
            if (code) {
              countryCode = code;
              const index = parts.indexOf(parts[i]);
              parts.splice(index, 1);
              break;
            }
          }
        }

        // find post code in the address
        for (var i = 0; i < parts.length; i++) {
          const postCodeMatch = parts[i].trim().match(/^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/gmi);
          if (postCodeMatch) {
            postcode = postCodeMatch[0];
            const index = parts.indexOf(parts[i]);
            parts.splice(index, 1);
            break;
          }
        }

        const isUKaddress = countryCode == 'GB' || postcode != null;

        // not all UK addresses have country specified
        if (countryCode == null && postcode != null) {
          countryCode = 'GB';
        }

        // if there are 4 or more parts remaining, then the last part likely to be the county/state
        if (isUKaddress && parts.length >= 4) {
          county = parts[parts.length - 1].trim();
          const index = parts.indexOf(parts[parts.length - 1]);
          parts.splice(index, 1);
        }

        // if there are 2 or more parts remaining, then the last part likely to be the city
        if (isUKaddress && parts.length >= 2) {
          city = parts[parts.length - 1].trim();
          const index = parts.indexOf(parts[parts.length - 1]);
          parts.splice(index, 1);
        }

                // if it's not UK address...
                if (!isUKaddress) {
                    //...get the zip code which is all numerical towards the end
                    for (var i = parts.length-1; i >= 0; i--) {
                        var zipCode = parts[i].trim().match(/(^\d{2,5}$)|(^\d{9}$)|(^\d{2,5}-\d{3,4}$)/gmi);
                        if(zipCode) {
                            postcode = zipCode[0];
                            const index = parts.indexOf(parts[i]);
                            parts.splice(index, 1);
                            break;
                        }
                    }
                    //... get the state code from the end
                    for (var i = parts.length-1; i >= 0; i--) {
                        var state = this.getJurisdictionCodeFromState(parts[i].trim());
                        if(state) {
                            county = state;
                            const index = parts.indexOf(parts[i]);
                            parts.splice(index, 1);
                            break;
                        }
                    }
                    //... get the last segment of the remaining parts as city
                    if (parts.length >= 2) {
                        city = parts[parts.length-1].trim();
                        const index = parts.indexOf(parts[parts.length-1]);
                        parts.splice(index, 1);
                    }

          // if it's canadain address
          if (countryCode == 'CA') {
            // get the post code from address
            const canadianPostCodeMatch = address.fullAddress.match(/([A-Z]\d[A-Z]\s?\d[A-Z]\d)/gmi);
            // replace post code in any parts
            if (canadianPostCodeMatch) {
              postcode = canadianPostCodeMatch[0];
              for (var i = parts.length - 1; i >= 0; i--) {
                if (parts[i].indexOf(postcode) >= 0) {
                  parts[i] = parts[i].replace(postcode, '');
                  // remove blank segment
                  if (parts[i].trim() == '') {
                    const index = parts.indexOf(parts[i]);
                    parts.splice(index, 1);
                  }
                  break;
                }
              }
              if (city) city = city.replace(postcode, '').trim();
            }
          }
        }

        address2 = new Address2({
          line1: street1 ?? this.getSafePart(0, parts),
          line2: street2 ?? this.getSafePart(1, parts),
          city: city ?? this.getSafePart(2, parts),
          // if part 4 is not a post code, then post code likely to be part 3, therefore county has to be null
          county: county ?? (this.getSafePart(4, parts) == null ? null : this.getSafePart(3, parts)),
          postcode: postcode ?? this.getSafePart(4, parts) ?? this.getSafePart(3, parts),
          countryIsoCode: countryCode
        })
      }
    }
    return address2;
  }

  private static getSafeCountryCode(parts: string[]): string {
    for (let part of parts) {
      part = part.trim();
      let result = this.getCodeFromCountry(part);
      if (result?.length > 0) {
        return result;
      }
    }
    return "";
  }

  private static getSafePart(pos: number, parts: string[]): string {
    if (parts.length > pos) {
      return parts[pos].trim();
    }
    return "";
  }

  static convertToAddress(address2: Address2): Address {
    let address1: Address;
    if (address2) {
      address1 = new Address({
        addressLine1: address2.line1,
        addressLine2: address2.line2,
        country: UtilityService.getCountryFromCode(address2.countryIsoCode),
        countyOrState: address2.county,
        locality: address2.city,
        postOrZipCode: address2.postcode,
        fullAddress: UtilityService.getAddress(address2)
      });
    }
    return address1;
  }

  static getCodeFromCountry(country: string): string {
    if (!country) { return ""; }
    return CountriesService.getJurisdictionCode(country);
  }
  static getJurisdictionCodeFromState(state: string): string {
    let code: string = "";
    if (!state) { return ""; }
    code = CountriesService.getJurisdictionCode(state);
    if (!code) {
      code = CountriesService.getJurisdictionCode('us_' + state);
    }    
    return code;
  }

  static getCodeFromNationality(nationality: string): string {
    return CountriesService.getNationalityCode(nationality);
  }

  static getNationalityFromCountry(country: string): string {
    if (!country) {
      return null;
    }
    let code = this.getCodeFromCountry(country);
    return this.getNationalityFromCode(code);
  }

  static getNationalityFromCode(code: string): string {
    return CountriesService.getNationalityName(code);
  }

  static getCountryFromJurisdictionCode(code: string) {
    if (!code) {
      return null;
    }
    if (code.length == 2) {
      return this.getCountryFromCode(code);
    }
    let parts = code.split("_");
    if (parts.length == 2) {
      return this.getCountryFromCode(parts[0]);
    }
    return null;
  }

  static getGenderFromString(gender: string): Gender {
    if (!gender) {
      return Gender.Unknown;
    }
    let lowgender = gender.toLowerCase();
    if (lowgender == "male") {
      return Gender.Male;
    }
    if (lowgender == "female") {
      return Gender.Female;
    }
    return Gender.Unknown;
  }

  static convertToLocalDateTime(date: Date, format: string) : string
  {
    if (!date) return null;
    var locale = navigator.language;
    let formattedDate : string;
    if (format == "long"){
      formattedDate = date.toLocaleDateString(locale, { year:"numeric", month: "short", day: "2-digit", hour: "numeric", minute:"2-digit", hour12: true} )
    }
    else if (format == "short"){
      formattedDate = date.toLocaleDateString(locale, { year:"numeric", month: "short", day: "2-digit"} )
    }
    else {
      formattedDate = date.toLocaleDateString(locale, { year:"numeric", month: "short", day: "2-digit", hour: "numeric", minute:"2-digit", hour12: true} )
    }
    return formattedDate;
  }
}

enum DataSets {
  PEPCURRENT = 'PEP-CURRENT', // - Only current PEPs
  PEPFORMER = 'PEP-FORMER', // - Only former PEPs
  PEPLINKED = 'PEP-LINKED', // - Only linked PEPs (PEP by Association)
  SANCURRENT = 'SAN-CURRENT', // - Only current Sanctions
  SANFORMER = 'SAN-FORMER', // - Only former Sanctions
  INS = 'INS', // - Insolvency
  RRE = 'RRE', // - Reputational Risk Exposure
  DD = 'DD', // - Disqualified Director
  POI = 'POI', // - Profile Of Interest
  REL = 'REL', // - Regulatory Enforcement List

  PEP = 'PEP', // - (Deprecated - ignore)
  SAN = 'SAN', //  - (Deprecated - ignore)
  SOE = 'SOE', //  - (Deprecated - ignore)
  SOECURRENT = 'SOE-CURRENT', //  - Only current SOE
  SOEFORMER = 'SOE-FORMER', //  - Only current SOE
}
