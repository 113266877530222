<div class="card-body  overflow-auto text-wrap text-break text-break">
    <div id="individual-profile-display">
        <div class="row">
            <div class="col">
                <img *ngIf="profileImageId" onerror="src='/assets/images/EDD-Avatar-individual.png'" [src]="'/media/'+profileImageId+'' | secure | async" class="avatar-image">
                <img *ngIf="profileImageId == null" src="/assets/images/EDD-Avatar-individual.png" class="avatar-image" />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h4>{{name}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <span id="badge-{{idvProfile.id}}" class="badge {{statusCodeToClass(idvProfile.status)}}" (mouseover)="onBadgeHover(idvProfile)">{{idvProfile.status}}</span>
            </div>
        </div>
        <div class="row" id="left-card">
            <div class="col-3">
                <label class="displaylabel-style">Gender:</label>
            </div>
            <div class="col-9">
                <span class="displaydetail-style">{{idvProfile?.gender}}</span>
            </div>
        </div>
        <div class="row" id="left-card">
            <div class="col-3">
                <label class="displaylabel-style">Date of birth:</label>
            </div>
            <div class="col-9">
                <span class="displaydetail-style">{{idvProfile?.dateOfBirth | date : 'yyyy-MM-dd' }}</span>
            </div>        
        </div>
        <div class="row" id="left-card">
            <div class="col-3">
                <label class="displaylabel-style">Address:</label>
            </div>
            <div class="col-9">
                <span class="displaydetail-style">{{address}}</span>
            </div>        
        </div>

        <div class="row" id="left-card">
            <div class="col-3">
                <label class="displaylabel-style">Country:</label>
            </div>
            <div class="col-9">
                <span class="displaydetail-style">{{country}}</span>
            </div>        
        </div>

        <div class="row" id="left-card">
            <div class="col-3">
                <label class="displaylabel-style">Nationality:</label>
            </div>
            <div class="col-9">
                <span class="displaydetail-style">{{nationality}}</span>
            </div>        
        </div>

        <div class="row" id="left-card">
            <div class="col-3">
                <label class="displaylabel-style">Created by:</label>
            </div>
            <div class="col-9">
                <span class="displaydetail-style">{{idvProfile.createdBy}}</span>
            </div>        
        </div>
        <div class="row" id="left-card">
            <div class="col-3">
                <label class="displaylabel-style">Created on:</label>
            </div>
            <div class="col-9">
                <span class="displaydetail-style">{{idvProfile.lastModifiedOn | date: 'yyyy-MM-dd'}}</span>
            </div>        
        </div>
    </div>
    <div *ngIf="showButtons">
        <button class="ms-3 btn btn-secondary viewMatches-btn" (click)="viewInvestigation()">
            Launch Investigation
        </button>
        <div *ngIf="isScreened(); else allowToScreen">
            <div *ngIf="isMonitored(); else allowToMonitor">
                <button disabled="true" class="ms-3 btn btn-secondary viewMatches-btn">
                    View Screened Client
                </button>    
                <button class="ms-3 btn btn-secondary viewMatches-btn" (click)="viewClient()">
                    View Monitored Client
                </button>    
            </div>
            <ng-template #allowToMonitor>
                <button class="ms-3 btn btn-secondary viewMatches-btn" (click)="viewClient()">
                    View Screened Client
                </button>
                <button class="ms-3 btn btn-secondary viewMatches-btn" (click)="addToMonitoring()">
                    Add to Monitoring
                </button>    
            </ng-template>    
        </div>
        <ng-template #allowToScreen>
            <button [disabled]="isScreened()" class="ms-3 btn btn-secondary viewMatches-btn" (click)="addToScreening()">
                Screen this client
            </button>    
            <button [disabled]="isMonitored()" class="ms-3 btn btn-secondary viewMatches-btn" (click)="addToMonitoring()">
                Add to Monitoring
            </button>
        </ng-template>
    </div>
</div>

<app-loader [show]="isBusy"></app-loader>
