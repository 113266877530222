import { PrintService } from '../services/print.service';
import { Component, HostListener, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { DashboardService } from '../services/dashboard.service';
import { ConfirmationDialogService } from '../_confirmation-dialog/ConfirmationDialog.service';
import { ShepherdService } from 'angular-shepherd';
import { steps as defaultSteps, defaultStepOptions} from '../dashboard/data';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentModule } from '../monitor/client-report/client-report.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnDestroy {

  constructor(private location: Location, public dashService: DashboardService, private confirmationDialogService : ConfirmationDialogService,
    private printService: PrintService, private modalService: NgbModal, private guideTour: ShepherdService, private router: Router) {

    this.dashService.selectedLAMPS = null;
  }

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange() {
    if (document.fullscreenElement) {
      this.dashService.isFullscreen = true;
    } else {
      this.dashService.isFullscreen = false;
    }

  }

  ngOnDestroy() {
    // guide tour
    this.guideTour = null;
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }


  public showReport = false;
  public showAuditReport = false;
  public currentModule = CurrentModule.SEARCH;

  backClicked(): void {
    if (this.dashService.hasChanged) {
      this.confirmationDialogService.confirm('Confirm', 'Discard changes and go back?')
      .then((confirmed) => {
        if(confirmed == true) {
          this.location.replaceState(this.location.path(),"","");
          this.location.back();
        }
      })
      .catch(() => console.log('User dismissed the dialog'));
    } else {
      let lastPage = history?.state?.pageName;
      if (lastPage) {
        this.router.navigate([lastPage], { state: {refresh: false}});
      }
      else {
        this.location.back()
      }
    }

  }

  createReport() {
    this.showReport = true;
  }

  closeReport(cancelled: boolean) {
    this.showReport = false;
  }

  closeAuditReport(cancelled: boolean) {
    this.showAuditReport = false;
  }

  displayAuditReport() {
    this.showAuditReport = true;
  }

  printGraphChanges() {
    this.printService.printHtml(`${this.dashService.getEntriesAsHtml()}`);
  }

  start() {
    this.guideTour.defaultStepOptions = defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(defaultSteps);
    this.guideTour.start();
  }

  //Graph full screen mode
  getFullscreenElement() {
    return document.fullscreenElement;
  }

  toggleFullscreen() {
    if (this.getFullscreenElement()) {
      this.dashService.isFullscreen = false;
      document.exitFullscreen()
    } else {
      this.dashService.isFullscreen = true;
      document.getElementById("graph-fullscreen").requestFullscreen().catch(console.log);
    }
  }

  openFullscreen() {
    this.toggleFullscreen();
  }

}
