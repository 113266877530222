import { Injectable } from '@angular/core';
import { Profile } from 'oidc-client';

@Injectable({
  providedIn: 'root'
})

export class ProfileService {
  private _profile: Profile;

  constructor() {
    var i = 0;
  }

  public setProfile(profile: Profile): void {
    this._profile = profile;
  }
  public getProfile(): Profile {
    return this._profile;
  }
  public clearProfile(): void {
    this._profile = null;
  }
  public get userId(): string {
    return this._profile.sub ?? this._profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'].toString();
  }
  public get firstName(): string {
    return this._profile.given_name ?? this._profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'].toString();
  }
  public set firstName(given_name: string) {
    this._profile.given_name = given_name;
  }
  public get lastName(): string {
    return this._profile.family_name ?? this._profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'].toString();
  }
  public set lastName(family_name: string) {
    this._profile.family_name = family_name;
  }
  public get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }
  public get tenantId(): string {
    return this._profile['tenant-id'].toString();
  }
  public get companyName(): string {
    return this._profile['company-name'].toString();
  }
  public get licenceType(): string {
    return this._profile['licence-type'].toString();
  }
  public get isMandatoryNotesEnabled(): boolean {
    return this._profile['mandatory-notes-enabled'].toString().toLowerCase() === true.toString();
  }
  public get firstLogin(): boolean {
    return this._profile['first-login'].toString().toLowerCase() === true.toString();
  }
  public get securityStamp(): string {
    return this._profile['AspNet.Identity.SecurityStamp'].toString();
  }
  public get email(): string {
    return this._profile.email ?? this._profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'].toString();
  }
  public set email(address: string) {
    this._profile.email = address;
  }
  public get emailVerified(): boolean {
    return this._profile.email_verified;
  }
  public get features(): string[] {
    if (!this._profile) return [];
    return this._profile['app-features']?.toString()?.split('|');
  }
  public get roles(): string[] {
    return this._profile.role ?? this._profile['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  }
  public get userName(): string {
    return this._profile.name ?? this._profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'].toString();
  }
  public get preferredUsername(): string {
    return this._profile.preferred_username;
  }
  // role checks
  public get IsAdministrator(): boolean {
    return this.roles?.indexOf('administrator') >= 0;
  }
  public get IsThemisAdministrator(): boolean {
    return this.roles?.indexOf('themisadmin') >= 0;
  }
  public get IsIntelligenceAdministrator(): boolean {
    return this.roles?.indexOf('intelligenceadmin') >= 0;
  }
  public get IsUser(): boolean {
    return this.roles?.indexOf('user') >= 0;
  }
  public IsInRole(role: string): boolean {
    return this.roles?.indexOf('role') >= 0;
  }
  public isSelfServeUser(): boolean {
    return this.licenceType !== 'Commercial';
  }
  // feature checks
  public idvEnabled(): boolean {
    return this.features.findIndex(v => v == "IDV") > -1;
  }

  public searchEnabled(): boolean {
    return this.features.findIndex(v => v == "Search") > -1;
  }

  public monitoringEnabled(): boolean {
    return this.features.findIndex(v => v == "Monitor") > -1;
  }

  public screeningEnabled(): boolean {
    return this.features.findIndex(v => v == "Screen") > -1;
  }

  public riskAssessmentEnabled(): boolean {
    return this.features.findIndex(v => v == "RiskAssessment") > -1;
  }

  public intelligenceEnabled(): boolean {
    return this.features.findIndex(v => v == "Intelligence") > -1;
  }

  public APIEnabled(): boolean {
    return this.features.findIndex(v => v == "API") > -1;
  }
}
