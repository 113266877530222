import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShepherdService } from 'angular-shepherd';
import { BusinessMatch, ClientResult, ClientResultsList, IndividualMatch, LAMPSStatus, ListClientsQuery, Log } from 'src/nswag';
import { MatchesService } from '../matching-service/matches.service';
import { CurrentModule } from '../monitor/client-report/client-report.component';
import { AddclientComponent } from '../monitor/manage/addclient/addclient.component';
import { ProfileService } from '../profile.service';
import { SelfServiceService } from '../self-service/services/self-service.service';
import { AlertService } from '../_alert';
import { ConfirmationDialogService } from '../_confirmation-dialog/ConfirmationDialog.service';
import { defaultStepOptions, steps as defaultSteps } from './data';
import { ScreeningService } from './services/screening.service';
import { ErrorService } from '../services/error.service';

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss'],
  animations: [
    trigger('inOutAnimation',[
      transition(':enter',[
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out', style({ height: '100%', opacity: 1 }))
      ]),
      transition(':leave',[
        style({ height: '100%', opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class ScreeningComponent implements OnInit, OnDestroy {
  public showAdvancedOptions = false;
  public showClientReport = false;
  public showHistoryReport = false;
  public auditLogs: Log[] = [];

  public showMain = true;
  public isLoading = false;
  public currentClient: ClientResult;
  public currentProfile: any;
  public individualMatches: IndividualMatch[];
  public businessMatches: BusinessMatch[];
  public searchData: ClientResultsList;
  public currentModule = CurrentModule.SCREEN;
  public firstLogin: boolean;
  public originalSteps = []; 
  public search: string;
  
  @Input() refresh: boolean = false;
  @Input() advancedSearchOptions = new ListClientsQuery;

  constructor(
    public screeningService: ScreeningService, private confirmationService: ConfirmationDialogService, private alertService: AlertService, private modalService: NgbModal, private matchService: MatchesService, private guideTour: ShepherdService, private profileService: ProfileService, private selfServeService: SelfServiceService, private route: ActivatedRoute, private router: Router, 
    private errorService: ErrorService) {
    this.firstLogin = this.profileService.firstLogin;
  }

  ngOnInit(): void {
    defaultSteps.map(x => this.originalSteps.push(x));
    // Initialise with all open alerts
    //this.screeningService.refreshSummary()
    if (history?.state?.refresh || this.refresh) {
      this.screeningService.loadLAMPSClients();
      this.screeningService.refreshClient();
    }
    // Initialise tour guide on user's first login
    if(this.profileService.isSelfServeUser() && this.firstLogin == true && !this.selfServeService.ViewedTourGuide) {
      setTimeout(() => {
        this.start()
      }, 5000);
    }

    let sessionId = null;
    //check url for session id
    this.route.queryParams.subscribe(params => {
      sessionId = params.session_id;

      if (sessionId){
        this.isLoading = true;

        this.selfServeService.completePaymentSession(sessionId).subscribe(result => {
          if (result.isSuccess){
            this.selfServeService.screeningCredits = result.data;
            this.alertService.success("Successfully added credits", { autoClose: true, keepAfterRouteChange: false }); 
          }
         
          this.isLoading = false;
        }, error => {
          let message;
          if (error?.response){
            const response = JSON.parse(error?.response);
            message = response.exception ?? response.errors[0].errorMessage;
          }
          else{
            message = error?.message;
          }
          
          this.alertService.error(message, { autoClose: false, keepAfterRouteChange: false }); 
          this.isLoading = false;
        });
      }
    })
  }

  ngOnDestroy() {
    // guide tour
    this.guideTour = null;
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }

  public displaySearchOptions() {
    if (this.showAdvancedOptions == true)
      this.showAdvancedOptions = false;
    else
      this.showAdvancedOptions = true;
  }

  public showScreen(): boolean {
    return this.screeningService.HasClients || this.advancedSearchOptions?.search != null;
  }

  public viewClientReport(client: ClientResult) {
    this.showMain = false;
    this.showClientReport = true;
    this.currentClient = null;
    this.currentProfile = null;
    this.individualMatches = null;
    this.businessMatches = null;

    this.loadAuditLogs(client);
    
    if (client?.matchStatus?.isMatched == LAMPSStatus.Changed || client?.matchStatus?.isMatched == LAMPSStatus.Yes) {
      if (client?.client?.id == this.screeningService.SelectedClient?.client?.id && (this.screeningService.SelectedBusinessMatchedProfile || this.screeningService.SelectedIndividualMatchedProfile)) {
        // We are already on the selected client record - so use the profile
        this.currentClient = client;
        if (client?.client?.business) {
          this.currentProfile = this.screeningService.SelectedBusinessMatchedProfile;
          this.loadBusinessMatches(client);
        }
        else {
          this.currentProfile = this.screeningService.SelectedIndividualMatchedProfile;
          this.loadIndividualMatches(client);
        }
      }
      else if (client?.client?.individual) {
        this.currentClient = client;
        this.screeningService.getIndividualMatchedProfile(client, false).subscribe(r => {
          if (r.isSuccess) {
            this.currentProfile = r.data
            this.loadIndividualMatches(client);
          }
        });
      }
      else {
        this.currentClient = client;
        this.screeningService.getBusinessMatchedProfile(client, false).subscribe(r => {
          if (r.isSuccess) {
            this.currentProfile = r.data
            this.loadBusinessMatches(client);
          }
        });
      }
    }
    else {
      this.currentClient = client;
      if (client?.client?.business) {
        this.loadBusinessMatches(client);
      }
      else {
        this.loadIndividualMatches(client);
      }
    }
  }

  private loadBusinessMatches(client: ClientResult) {
    this.screeningService.getBusinessMatches(client).subscribe(r => {
      if (r.isSuccess) {
        this.businessMatches = r.data.matches.sort((a, b) => b.score - a.score);
        this.updateMatches(this.businessMatches)
      }
    });
  }

  private loadIndividualMatches(client: ClientResult) {
    this.screeningService.getIndividualMatches(client).subscribe(r => {
      if (r.isSuccess) {
        this.individualMatches = r.data.matches.sort((a, b) => b.score - a.score);
        this.updateMatches(this.individualMatches)
      }
    });
  }

  protected updateMatches(matches: any[]) {
    if (this.currentProfile) {
      for (let match of matches) {
        if (!match.currentStatus) {
          if (match.resourceId == this.currentProfile.resourceId) {
            match.currentStatus = "true-positive";
          }
          else {
            match.currentStatus = "false-positive";
          }
        }
      }
    }
  }

  public viewHistoryReport(client: ClientResult) {
    this.showMain = false;
    this.currentClient = client;
    this.showHistoryReport = true;
    this.loadAuditLogs(client);
  }

  private loadAuditLogs(client:ClientResult) {
    this.auditLogs = [];
    this.screeningService.getClientAuditLogs(client.client.id).subscribe(result => {
      this.auditLogs = result.data.logEntries;
    });    
  }

  public closeClientReport(cancelled: boolean) {
    this.showMain = true;
    this.showClientReport = false;
  }

  public closeHistoryReport(cancelled: boolean) {
    this.showMain = true;
    this.showHistoryReport = false;
  }

  public openClientArchive() {
    this.router.navigate([this.currentModule.toLocaleLowerCase(), 'archive'], { state: { module: this.currentModule, refresh: true } });
  }

  public deleteClient(client: ClientResult) {
    this.confirmationService.confirm("Archive record?", null, true, "Ok", "Cancel", "sm", true).then(r => {
      if (r) {
        this.screeningService.deleteClient(client, this.confirmationService?.reason).subscribe(r => {
          this.screeningService.removeClient(client);
          if (this.screeningService.ClientList.length === 0 && this.screeningService.ClientListPage > 1) {
            this.screeningService.loadLAMPSClientsPage(this.screeningService.ClientListPage - 1);
          }
          this.alertService.success("The client has been archived", { autoClose: true });
        });
      }
    })
    .catch((onRejected) => { /* modal closed */ });
  }

  public selectClient(client: ClientResult) {
    this.screeningService.SetSelectedClient(client);
  }

  public setSearch(value: string) {
    this.search = value;
  }

  public clientChange(client: ClientResult) {
    this.screeningService.refreshSelectedClient(client);
  }

  public searchClient() {
    this.advancedSearchOptions.search = this.search;
    this.screeningService.searchForClients(this.advancedSearchOptions);
  }

  public setSearchOptions(data: ListClientsQuery) {
    this.advancedSearchOptions = data;
  }

  public changePage(page: number) {
    this.screeningService.loadLAMPSClientsPage(page);
  }
  
  public addToMonitoring(client: ClientResult) {
    this.errorService.reset();
    let editor = new AddclientComponent(this.modalService);
    editor.editClient(client, "Move Client to Monitoring", "Move to monitoring");
    editor.saveClient.subscribe(result => {
      this.screeningService.addToMonitoring(result).subscribe(r => {
        if (r.isSuccess) {
          this.alertService.success("The client has been successfully added to Monitoring. Select Monitoring from the menu to view the ongoing status of this client.", { autoClose: true });
          this.screeningService.removeClient(client);
          this.modalService.dismissAll();
        }
      },
        (error) => {
          this.errorService.setProblemDetailsFromError(error);
          this.modalService.dismissAll();
        });
    });
  }

  public searchMatches() {
    let client = this.screeningService.SelectedClient.client;
    if (client?.individual) {
      this.matchService.findIndividualMatchFromScreening(this.screeningService.SelectedClient?.client).subscribe(c => {
        this.screeningService.refreshSelectedClient(c);
      });
    }
    else if (client?.business) {
      this.matchService.findBusinessMatchFromScreening(this.screeningService.SelectedClient?.client).subscribe(c => {
        this.screeningService.refreshSelectedClient(c);
      });
    }
  }

  public viewMatches() {
    let client = this.screeningService.SelectedClient.client;
    let profile = this.screeningService.SelectedProfile;
    if (client?.individual) {
      this.matchService.selectIndividualMatchFromScreening(client, profile).subscribe(c => {
        this.screeningService.SetSelectedClient(c);
      });
    }
    else if (client?.business) {
      this.matchService.selectBusinessMatchFromScreening(client, profile).subscribe(c => {
        this.screeningService.SetSelectedClient(c);
      });
    }
  }
  
  //Tour guide start function
  start() {
    // reset the originalSteps array if its length is not the same as defaultSteps
    if (defaultSteps.length !== this.originalSteps.length) {
      this.originalSteps = [];
      defaultSteps.map(x => this.originalSteps.push(x));
    } 
    //if there isn't any screen client in the list
    let stepsToRemove = [ 3, 4, 5, 6, 7]; //List of step numbers to remove
    if (this.screeningService?.ClientList?.length == 0) {
      for (let i = stepsToRemove.length - 1; i>= 0; i--) {
        let stepIndex = this.originalSteps.indexOf(this.originalSteps[stepsToRemove[i]]);
        if (stepIndex > -1) {
          this.originalSteps.splice(stepIndex, 1);
        }
      }
    } //skip the tour guide steps with the explanation
    // remove step 2 for self-serve user
    if (!this.profileService.isSelfServeUser()) {
      const freeUser = this.originalSteps.indexOf(this.originalSteps[2]);
      if (freeUser > -1) {
        this.originalSteps.splice(freeUser, 1);
      }
    }

    this.guideTour.defaultStepOptions = defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(this.originalSteps);
    this.guideTour.start();
    this.selfServeService.SetViewedTourGuide(true);
  }
}
