export enum FeatureState {
    SEARCH = 'search',
    SCREEN = 'screen',
    MONITOR = 'monitor',
    OUTOFCREDITS = 'OutOfCredits',
    IDV = 'idv',
    ASSESSMENT = 'risk-assessment',
    APISETTINGS = 'api',
    ASSESSMENTSETTINGS = 'assessment-settings',
    INTELLIGENCE = 'intelligence'
}
export enum ExceptionPropertyNames
{
    RESPONSETOOLARGE = "ResponseTooLarge"
}