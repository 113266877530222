import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiResponseOfListOfString, BusinessMatch, ClientResult, IndividualMatch, LAMPSStatus, LAMPSTypes, ListClientsQuery, Log, UploadFileMetadata } from 'src/nswag';
import { MatchesService } from '../matching-service/matches.service';
import { AlertService } from '../_alert';
import { ConfirmationDialogService } from '../_confirmation-dialog/ConfirmationDialog.service';
import { AddclientComponent } from './manage/addclient/addclient.component';
import { MonitorService } from './services/monitor.service';
import { ShepherdService } from 'angular-shepherd';
import { steps as defaultSteps, defaultStepOptions} from '../monitor/data';
import { trigger, transition, style, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import { CurrentModule } from './client-report/client-report.component';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error.service';

@Component({
  selector: 'mon-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss'],
  animations: [
    trigger('inOutAnimation',[
      transition(':enter',[
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out', style({ height: '100%', opacity: 1 }))
      ]),
      transition(':leave',[
        style({ height: '100%', opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})

export class MonitorComponent implements OnInit, OnDestroy {

  public showAdvancedOptions = false;
  public showClientReport = false;
  public showHistoryReport = false;
  public auditLogs: Log[] = [];

  public showMain = true;
  public isLoading = false;

  public currentClient: ClientResult;
  public currentProfile: any;
  public individualMatches: IndividualMatch[];
  public businessMatches: BusinessMatch[];
  public validationErrors: string[];
  public currentModule = CurrentModule.MONITOR;

  public orderBy: string = "AlertThenCreation";
  public search: string;
  public showOpenAlerts : boolean = false;
  public lampsType: LAMPSTypes;

  @Input() orderByAscending: boolean = false;
  @Input() advancedSearchOptions = new ListClientsQuery;
  @Output() isMonitoring: boolean;
  constructor(public monitorService: MonitorService, private confirmationService: ConfirmationDialogService, private alertService: AlertService, private modalService: NgbModal, private matchService: MatchesService, private guideTour: ShepherdService, private router: Router, private errorService: ErrorService) {
  }

  ngOnInit(): void {
    // Initialise with all open alerts
    this.isMonitoring = true;
    this.monitorService.refreshSummary();
    if(history?.state?.refresh) {
      this.monitorService.loadLAMPSClients();
      this.monitorService.refreshClient();
    }
  }

  ngOnDestroy() {
    // guide tour
    this.guideTour = null;
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }

  sortPage(value: boolean) {
    // set globals
    this.orderByAscending = value;
    this.searchClient();
  }

  public setLampsType(lampsType: LAMPSTypes){
    this.lampsType = lampsType;
  }

  public setOpenAlertFilter(showOpenAlerts: boolean){
    this.showOpenAlerts = showOpenAlerts;
  }

  public resetAllFilter(showOpenAlerts: boolean = false){
    this.setSearch(null);
    this.resetAdvancedOptions(showOpenAlerts, this.lampsType);
  }

  public resetAdvancedOptions(showOpenAlerts: boolean, lampsType: LAMPSTypes){
    this.advancedSearchOptions = new ListClientsQuery();
    this.advancedSearchOptions.isOpen = showOpenAlerts;
    switch (lampsType) {
      case LAMPSTypes.Adv:
        this.advancedSearchOptions.hasAdvMedia = true;
        break;
      case LAMPSTypes.Law:
        this.advancedSearchOptions.hasLaw = true;
        break;
      case LAMPSTypes.Pep:
        this.advancedSearchOptions.hasPep = true;
        break;
      case LAMPSTypes.San:
        this.advancedSearchOptions.hasSanctions = true;
        break;
      default:
        break;
    }
    this.showAdvancedOptions = true;
  }

  public resetValidation() {
    this.validationErrors = null;
  }

  public displaySearchOptions() {
    if (this.showAdvancedOptions == true)
      this.showAdvancedOptions = false;
    else
      this.showAdvancedOptions = true;
  }

  public viewClientReport(client: ClientResult) {
    this.showMain = false;
    this.showClientReport = true;
    this.currentClient = null;
    this.currentProfile = null;
    this.individualMatches = null;
    this.businessMatches = null;

    this.loadAuditLogs(client);

    if (client?.matchStatus?.isMatched == LAMPSStatus.Changed || client?.matchStatus?.isMatched == LAMPSStatus.Yes ) {
      if (client?.client?.id == this.monitorService.SelectedClient?.client?.id && (this.monitorService.SelectedBusinessMatchedProfile || this.monitorService.SelectedIndividualMatchedProfile)) {
        // We are already on the selected client record - so use the profile
        this.currentClient = client;
        if (client?.client?.business) {
          this.currentProfile = this.monitorService.SelectedBusinessMatchedProfile;
          this.loadBusinessMatches(client);
        }
        else {
          this.currentProfile = this.monitorService.SelectedIndividualMatchedProfile;
          this.loadIndividualMatches(client);
        }
      }
      else if (client?.client?.individual) {
        this.currentClient = client;
        this.monitorService.getIndividualMatchedProfile(client, false).subscribe(r => {
          if(r.isSuccess) {
            this.currentProfile = r.data;
            this.loadIndividualMatches(client);
          }
        });
      }
      else {
        this.currentClient = client;
        this.monitorService.getBusinessMatchedProfile(client, false).subscribe(r => {
          if(r.isSuccess) {
            this.currentProfile = r.data
            this.loadBusinessMatches(client);
          }
        });
      }
    }
    else {
      this.currentClient = client;
      if (client?.client?.business) {
        this.loadBusinessMatches(client);
      }
      else {
        this.loadIndividualMatches(client);
      }
    }
  }

  private loadBusinessMatches(client: ClientResult) {
    this.monitorService.getBusinessMatches(client).subscribe(r => {
      if (r.isSuccess) {
        this.businessMatches = r.data.matches.sort((a,b) => b.score - a.score);
        this.updateMatches(this.businessMatches)
      }
    });
  }

  private loadIndividualMatches(client: ClientResult) {
    this.monitorService.getIndividualMatches(client).subscribe(r => {
      if (r.isSuccess) {
        this.individualMatches = r.data.matches.sort((a,b) => b.score - a.score);
        this.updateMatches(this.individualMatches)
      }
    });
  }

  protected updateMatches(matches: any[]) {
    if (this.currentProfile) {
      for(let match of matches) {
        if (!match.currentStatus) {
          if (match.resourceId == this.currentProfile.resourceId) {
            match.currentStatus = "true-positive";
          }
          else {
            match.currentStatus = "false-positive";
          }
        }
      }
    }
  }

  public viewHistoryReport(client: ClientResult) {
    this.showMain = false;
    this.currentClient = client;
    this.showHistoryReport = true;
    this.loadAuditLogs(client);
  }

  private loadAuditLogs(client:ClientResult) {
    this.auditLogs = [];
    this.monitorService.getClientAuditLogs(client.client.id).subscribe(result => {
      this.auditLogs = result.data.logEntries;
    });    
  }

  public closeClientReport(cancelled: boolean) {
    this.showMain = true;
    this.showClientReport = false;
  }

  public closeHistoryReport(cancelled: boolean) {
    this.showMain = true;
    this.showHistoryReport = false;
  }

  public deleteClient(client: ClientResult) {
    this.confirmationService.confirm("Archive record?", null, true, "Ok", "Cancel", "sm", true).then(r => {
      if (r) {
        console.log(this.confirmationService?.reason);
        this.monitorService.deleteClient(client, this.confirmationService?.reason).subscribe( r => {
          var index = this.monitorService.ClientList.indexOf(client,0);
          if (index > -1) {
            this.monitorService.ClientList.splice(index,1);
          }
          this.monitorService.refreshSummary();
          this.monitorService.removeClient(client);
          if (this.monitorService.ClientList.length === 0 && this.monitorService.ClientListPage > 1) {
            this.monitorService.loadLAMPSClientsPage(this.monitorService.ClientListPage - 1);
          }
          this.alertService.success("The client has been archived", { autoClose: true });
        });
      }
    });
  }

  public selectClient(client: ClientResult) {
    this.monitorService.SetSelectedClient(client);
  }

  public editClient(client: ClientResult) {
    this.errorService.reset();
    let editor = new AddclientComponent(this.modalService);
    editor.editClient(client);
    editor.saveClient.subscribe(result => {
      this.saveClient(result);
    });
    // var modalOptions: NgbModalOptions = {
    //   backdrop: 'static',
    //   keyboard: false,
    //   scrollable: true
    // }
    // const modalRef = this.modalService.open(ClientEditorComponent, modalOptions);
    // modalRef.componentInstance.Client = client;
  }

  public clientChange(client: ClientResult) {
    this.monitorService.refreshSelectedClient(client);
  }

  public searchClient() {
    this.advancedSearchOptions.search = this.search;
    this.advancedSearchOptions.orderBy = this.orderBy;
    this.advancedSearchOptions.orderByAsc = this.orderByAscending;
    this.monitorService.searchForClients(this.advancedSearchOptions);
  }

  public setSearch(value: string) {
    this.search = value;
  }

  public setSearchOptions(data: ListClientsQuery) {
    this.advancedSearchOptions = data;
  }

  public setIsLoading = (loading: boolean): void => {
    this.isLoading = loading;
  }

  public importSuccessful = (): void => {
    this.monitorService.refreshSummary();
  }

  public importClients = (file: UploadFileMetadata): Observable<ApiResponseOfListOfString> => {
    return this.monitorService.uploadImportFile(file);
  }

  public changePage(page: number) {
    this.monitorService.loadLAMPSClientsPage(page);
  }

  public saveClient(result: ClientResult) {
    
    this.errorService.reset();

    if (result?.client?.business) {
      this.monitorService.saveBusiness(result.client).subscribe(r => {
        if (r.isSuccess) {
          let current = this.monitorService.ClientList.find( v => {
            return v.client.id == r.data.id;
          });
          if (current) {
            // remap updated client data
            current.client.business.init(r.data);
            current.client.address.init(r.data.address);
          }
          else {
            // remap created client data
            result.client.business = r.data;
            result.client.id = r.data.id;
            if (r.data.address) {
              result.client.address = r.data.address;
            }
            this.monitorService.ClientList.unshift(result);
            this.monitorService.refreshSummary();
          }
          this.alertService.success("The client has been saved", { autoClose: true });
          this.modalService.dismissAll();
        }
      },
      (error) => {
        this.errorService.setProblemDetailsFromError(error);
        this.modalService.dismissAll();
      });
    }
    else if (result?.client?.individual) {
      this.monitorService.saveIndividual(result.client).subscribe(r => {
        if (r.isSuccess) {
          let current = this.monitorService.ClientList.find( v => {
            return v.client.id == r.data.id;
          });
          if (current) {
            // remap updated client data
            current.client.individual.init(r.data);
            current.client.address.init(r.data.address);
          }
          else {
            // remap created client data
            result.client.individual = r.data;
            result.client.id = r.data.id;
            if (r.data.address) {
              result.client.address = r.data.address;
            }
            this.monitorService.ClientList.unshift(result);
            this.monitorService.refreshSummary();
          }
          this.alertService.success("The client has been saved", { autoClose: true });
          this.modalService.dismissAll();
        }
      },
      (error) => {
        this.errorService.setProblemDetailsFromError(error);
        this.modalService.dismissAll();
      });
    }
  }

  public openClientArchive() {
    this.router.navigate([this.currentModule.toLocaleLowerCase(), 'archive'], { state: { module: this.currentModule, refresh: true } });
  }

  public searchMatches() {
    let client = this.monitorService.SelectedClient.client;
    if (client?.individual) {
      this.matchService.selectIndividualMatchFromNewClient(client).subscribe(c => {
        this.monitorService.refreshSelectedClient(c);
      });
    }
    else if (client?.business) {
      this.matchService.selectBusinessMatchFromNewClient(client).subscribe(c => {
        this.monitorService.refreshSelectedClient(c);
      });
    }
  }

  public viewMatches() {
    let client = this.monitorService.SelectedClient.client;
    let profile = this.monitorService.SelectedProfile;
    if (client?.individual) {
      this.matchService.selectIndividualMatchFromClient(client, profile).subscribe(c => {
        this.monitorService.SetSelectedClient(c);
        this.monitorService.refreshSummary();
      });
    }
    else if (client?.business) {
      this.matchService.selectBusinessMatchFromClient(client, profile).subscribe(c => {
        this.monitorService.SetSelectedClient(c);
        this.monitorService.refreshSummary();
      });
    }
  }

  public start() {
    this.guideTour.defaultStepOptions = defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(defaultSteps);
    this.guideTour.start();
  }
}
