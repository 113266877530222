import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorService } from './services/error.service';
import { ErrorDetail } from './models/ErrorDetail';
  
  @Injectable()
  export class ThemisHttpInterceptor implements HttpInterceptor {
    
    constructor(private router: Router, private alertService: AlertService, private errorService: ErrorService) {}

    alertOptions = {
      autoClose: false,
      keepAfterRouteChange: false
    };

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      var headers = request.headers;
      headers = headers.set('TimezoneOffset', new Date().getTimezoneOffset().toString());
      headers = headers.set('Timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      headers = headers.set('Language', navigator.language);
      request = request.clone({headers})
      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {

            if (error.status == 401 || error.status == 403) {
              if(error.status == 401) {
                // angular auth guard will send many requests to index.html file with 401
                //this.router.navigate(['/authentication/login']);
              }
              if(error.status == 403) {
                this.router.navigate(['/authentication/login']);
                this.alertService.warn('Access denied, please login again with sufficient permission', this.alertOptions);
              }
              return of(error as any);
            }
            else {
              if (error.error instanceof ErrorEvent) {
                // client-side error
                this.handleClientError(error.error);
                } else {
                // server-side error
                this.handleServerError(error);
                }
            }

            return throwError(error);
          })
        );
    }

    handleClientError(error: ErrorEvent) {
        const errorMessage = `Error: ${error.error.message}`;
        this.alertService.error(errorMessage, this.alertOptions);
    }

    handleServerError(response: HttpErrorResponse) {    
      if (response?.error?.type === 'application/problem+json' && typeof response?.error?.text === 'function')
        response.error.text().then((text: string) => {
            const json = JSON.parse(text);
            
            const problemDetail = json as ErrorDetail;

            //this.errorService.setProblemDetails(problemDetail)

          }).catch((err) => {
            throwError(err);
          });
    }

  }
