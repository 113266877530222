<h5 *ngIf="title">{{title}}</h5>
<div *ngIf="expandedEvents?.length >0; else noevents">
    <div *ngFor="let event of expandedEvents; let pos=index">
        <div class="pt-2 law-event">
            <span *ngIf="event.type" ><strong>Event:</strong> {{event.type}}<br></span>
            <span *ngIf="event?.dateIso"><strong>Date:</strong> {{event.dateIso}}<br></span>
            <span>{{displayAmount(event)}}</span>
        </div>
        <div class="row">
            <mon-evidences [clientId]="clientId" [profile]="profile" [evidences]="event.evidences" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-evidences>
        </div>
        <hr *ngIf="pos < expandedEvents.length - 1"/>
    </div>
</div>

<ng-template #noevents>
    <div *ngIf="noDataMessage" class="row">
    <p>{{noDataMessage}}</p>
    </div>
</ng-template>
